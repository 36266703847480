import React from 'react';
import { node, shape, bool } from 'prop-types';
import { ThemeContext, defaultThemeValues } from './ThemeContext';

const ThemeProvider = ({ children = null, theme = null }) => {
  const themeContext = {
    ...defaultThemeValues(),
    ...theme
  };

  return (
    <ThemeContext.Provider value={themeContext}>
      {children}
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: node,
  theme: shape({
    inverseTheme: bool
  })
};
ThemeProvider.displayName = 'ThemeProvider';

export { ThemeProvider };
