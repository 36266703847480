import React, { useRef, Suspense } from 'react';
import {
  string, arrayOf, shape, elementType, node
} from 'prop-types';
import classNames from 'classnames';
import useClientNode from '../../hooks/useClientNode';
import { useThemeContext } from '../generic-ui/ThemeContext';
import { HeaderIconTextButton } from '../generic-ui/HeaderIconTextButton';
import { useDrawerContext } from '../drawer-ui/DrawerContext';
import { useDesktopBreakpoint } from '../../utils/headerUtils';
import { headerEvent } from '../../events/header.events';
import { useUserContext } from '../generic-ui/UserContext';

const SignInBubble = React.lazy(() => import(
  /* webpackChunkName: "SignInBubble" */
  '@thd-olt-component-react/sign-in-bubble')
  .then(module => ({ default: module.SignInBubble }))
  // eslint-disable-next-line no-console
  .catch((error) => console.warn(`Error occurred while lazy loading SignInBubble in Header - ${error}`)));

const HeaderNavigationButtons = ({ navButtonsData = {} }) => {
  const [navigationNode, setNavigationNode] = useClientNode();
  const isDesktop = useDesktopBreakpoint();
  const accountSignBubbleRef = useRef(null);
  const { inverseTheme } = useThemeContext();

  const { drawerState, drawerDispatch } = useDrawerContext();
  const { thdCustomer, storefront } = useUserContext();

  const handleOnClick = ({
    href, name, customDrawerTitle, flyoutId, navigationLevel
  }) => {
    if (href) {
      headerEvent.publish({
        eventName: 'header.header-click',
        payload: { clickPath: name?.toLowerCase() }
      });
      return;
    }

    const drawerDispatchOptions = {};
    const { open: stateOpen, name: stateName } = drawerState;

    if (stateOpen && stateName === name) {
      drawerDispatchOptions.type = 'clear';
    } else {
      drawerDispatchOptions.type = name;
      drawerDispatchOptions.title = customDrawerTitle || name;
      drawerDispatchOptions.flyoutId = flyoutId;
      drawerDispatchOptions.navigationLevel = navigationLevel;
    }

    drawerDispatch(drawerDispatchOptions);
  };

  const style = {
    navContainer: classNames(
      'print:sui-contents',
      'sui-fixed',
      'sui-w-full',
      'sui-left-0',
      'sui-bottom-0',
      'sui-transition-[bottom]',
      'sui-duration-500',
      'sui-border-solid',
      'sui-border-t-1',
      'sui-border-primary',
      'lg:sui-static',
      'lg:sui-w-auto',
      'lg:sui-border-t-0',
      'lg:sui-left-auto',
      'lg:sui-bottom-auto',
      'lg:sui-bg-inherit',
      { 'sui-bg-pro sui-text-inverse': inverseTheme },
      { 'sui-bg-primary sui-text-primary': !inverseTheme },
      '[.header-bottom-nav-hidden_&]:sui-hidden',
      /**
      * header-out-of-view
      * - is an arbitrary variant that's set by PositionedHeader
      * - when present this fixed container will slide down
      */
      '[.header-out-of-view_&]:-sui-bottom-20',
    ),
    itemContainer: classNames(
      'sui-flex',
      'sui-justify-around',
      'sui-mx-3',
      'sui-my-4',
      'sui-h-fit',
      'lg:sui-gap-6',
      'lg:sui-mx-0',
      'lg:sui-my-0'
    )
  };

  // This data attribute is a dependency of the useHeaderObserver hook.
  // design to be removed or added based on the position of this component
  if (navigationNode) navigationNode.toggleAttribute('data-position-bottom', !isDesktop);

  return (
    <div
      id="header-nav-container"
      className={style.navContainer}
      ref={setNavigationNode}
    >
      <div className={style.itemContainer}>
        {navButtonsData.map((button) => (
          <HeaderIconTextButton
            name={button.name}
            label={button.customLabel}
            ref={button.name === 'Account' ? accountSignBubbleRef : null}
            href={button.href}
            textColor={inverseTheme ? 'inverse' : 'primary'}
            icon={button.icon}
            iconSize="responsive"
            iconColor={inverseTheme ? 'inverse' : 'primary'}
            activeName={drawerState.name}
            key={button.name}
            onClick={() => handleOnClick(button)}
            data-testid={`header-button-${button.name}`}
            aria-label={button.href
              ? `click to redirect to view ${button.name} page`
              : `open drawer to view ${button.name}`}
          />
        ))}
      </div>
      {navigationNode && !thdCustomer?.isLoggedIn && !storefront && (
        <Suspense fallback={null}>
          <SignInBubble data-testid="account-bubble-signin" ref={accountSignBubbleRef} />
        </Suspense>
      )}
    </div>
  );
};

HeaderNavigationButtons.propTypes = {
  navButtonsData: arrayOf(shape({
    flyoutId: string,
    href: string,
    icon: elementType,
    customLabel: node,
    name: string.isRequired,
    customDrawerTitle: node,
  })),
};

HeaderNavigationButtons.dataModel = {};
HeaderNavigationButtons.displayName = 'HeaderNavigationButtons';

export { HeaderNavigationButtons };
