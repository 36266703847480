import React, { useEffect } from 'react';
import { bool, node, shape, oneOf } from 'prop-types';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { ThemeProvider } from '../generic-ui/ThemeProvider';
import { DrawerProvider } from '../drawer-ui/DrawerProvider';
import '../generic-ui/styles/default.scss';
import { headerAnalytics } from '../../events/analytics.listener';
import { headerVersion } from '../../events/headerVersion.listener';
import { UserProvider } from '../generic-ui/UserProvider';
import { HeaderProvider } from '../generic-ui/HeaderProvider';

const HeaderController = ({
  children = null,
  theme = null,
  options = {
    skipContactCenter: true,
    skipStorefront: true
  },
  featureSwitches = {
    isRecentlyViewedEnabled: false
  }
}) => {

  useEffect(() => {
    headerAnalytics.listen();
    headerVersion.listen();
  }, []);

  return (
    <ErrorBoundary id="header-controller" name="header-controller">
      <HeaderProvider featureSwitches={featureSwitches}>
        <ThemeProvider theme={theme}>
          <UserProvider options={options}>
            <DrawerProvider>
              {children}
            </DrawerProvider>
          </UserProvider>
        </ThemeProvider>
      </HeaderProvider>
    </ErrorBoundary>
  );
};

HeaderController.displayName = 'HeaderController';

HeaderController.propTypes = {
  children: node,
  theme: shape({
    inverseTheme: bool
  }),
  options: shape({
    skipContactCenter: bool,
    skipStorefront: bool
  }),
  featureSwitches: shape({
    isRecentlyViewedEnabled: bool
  })
};

export { HeaderController };
