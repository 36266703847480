import { createContext, useContext } from 'react';

export const defaultUserContextValues = () => {
  return {
    contactCenter: {},
    thdCustomer: null,
    storefront: null
  };
};

export const UserContext = createContext(defaultUserContextValues());

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};
