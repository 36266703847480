import React from 'react';
import { hydrate, hydrateRoot } from 'react-dom';
import { Header } from '../../components/header-ui/Header';
import { Footer } from '../../components/footer-ui/Footer';
import HeaderClientContext from '../../components/header-ui/HeaderClientContext';
import { hfClientManager } from '../builder/client-side-builder';

const headerApp = (
  <HeaderClientContext>
    <Header />
  </HeaderClientContext>
);

const headerRootElement = document.getElementById('header-static');

if (headerRootElement?.hasChildNodes()) {
  if (hydrateRoot !== undefined) {
    hydrateRoot(headerRootElement, headerApp);
  } else {
    hydrate(headerApp, headerRootElement);
  }

  const footerRootElement = document.getElementById('footer-static');
  if (footerRootElement?.hasChildNodes()) {
    if (hydrateRoot !== undefined) {
      hydrateRoot(footerRootElement, <Footer />);
    } else {
      hydrate(<Footer />, footerRootElement);
    }
  }

} else {
  // prerender HTML does not exist, fallback to client-side render
  hfClientManager.headerfooter.buildAndRender({
    headerNode: headerApp,
    footerNode: <Footer />,
    isB2B: false,
    footerId: 'footer-static',
    headerId: 'header-static'
  });
}
