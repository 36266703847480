import React from 'react';
import { IconButton } from '@one-thd/sui-atomic-components';
import { Barcode } from '@one-thd/sui-icons';
import { useThemeContext } from '../generic-ui/ThemeContext';
import { HOST_CONSTANTS } from '../../constants';

const BarcodeScannerLoader = () => import(
  /* webpackChunkName: "barcode-scanner" */
  '~/barcode-scanner/dist/main'
).then((barcodeScannerPromise) => {
  return barcodeScannerPromise;
}).catch((err) => {
  // eslint-disable-next-line no-console
  console.warn(err);
});

/**
 * Component provides Barcode Icon, Button, and Barcode-Scanner Module-Loader for Header Search Bar.
 */
const HeaderBarcodeSearch = () => {
  const { inverseTheme } = useThemeContext();
  const hostName = HOST_CONSTANTS.DEFAULT_HOST || '/';

  const handleOpenScanner = async () => {
    BarcodeScannerLoader().then((module) => {
      if (module?.default !== 'undefined') {
        module.default.startScanning({ hostName });
      }
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.warn(err);
    });
  };

  const inverseStylingClasses = inverseTheme
    ? 'sui-text-inverse sui-fill-inverse'
    : 'sui-text-primary';

  const iconWrapperClasses = `sui-flex ${inverseStylingClasses}`;

  return (
    <div className={iconWrapperClasses} data-testid="header-barcode-search">
      <IconButton
        aria-label="Button for Searching by Scanning a Barcode with Your Phone"
        icon={Barcode}
        color={inverseTheme ? 'inverse' : 'primary'}
        buttonSize="relaxed"
        iconSize="regular"
        onClick={handleOpenScanner}
      />
    </div>
  );
};

HeaderBarcodeSearch.displayName = 'HeaderBarcodeSearch';
HeaderBarcodeSearch.propTypes = {};

export { HeaderBarcodeSearch };
