import React from 'react';
import {
  string, bool, func, shape
} from 'prop-types';
import { MenuItem, Typography } from '@one-thd/sui-atomic-components';

export const CLEAR_ALL_BUTTON_ID = 'clear-search-history-button';

export const SuggestionsMenuTitle = ({
  headerTitle = '',
  showClearAllButton = true,
  handleClearAll = func
}) => {
  return (
    <div className="sui-flex sui-justify-between sui-items-baseline">
      {headerTitle && (
        <div
          id="suggestions-menu-title"
          data-testid="suggestions-menu-title"
          className="sui-px-4 sui-py-2"
        >
          <Typography color="primary" height="loose" weight="bold" variant="body-base">
            {headerTitle}
          </Typography>
        </div>
      )}
      {showClearAllButton && (
        <div className="sui-items-center">
          <MenuItem
            id={CLEAR_ALL_BUTTON_ID}
            component="div"
            onClick={handleClearAll}
            data-testid={CLEAR_ALL_BUTTON_ID}
            tabIndex={0}
          >
            <Typography
              height="loose"
              decoration="underline"
              variant="body-xs"
            >
              Clear All
            </Typography>
          </MenuItem>
        </div>
      )}
    </div>
  );
};

SuggestionsMenuTitle.displayName = 'SuggestionsMenuTitle';
SuggestionsMenuTitle.propTypes = {
  headerTitle: string,
  showClearAllButton: bool,
  handleClearAll: func,
};
