import { useState, useEffect } from 'react';
import { useUserContext } from '../../generic-ui/UserContext';

const MyAccountMenuTitle = () => {
  const { thdCustomer, storefront } = useUserContext();
  const { buyerOrg: companyName } = storefront || {};
  const [title, setTitle] = useState('Sign In or Create an Account');

  useEffect(() => {
    if (storefront) {
      const greeting = 'Welcome Back';
      if (companyName) {
        setTitle(`${greeting}, ${companyName}`);
      } else {
        setTitle(greeting);
      }
      return;
    }

    if (thdCustomer?.isLoggedIn) {
      const greetingType = thdCustomer?.isB2BCustomer ? 'Welcome Back' : 'Hello';
      if (thdCustomer?.username && thdCustomer?.username.toLowerCase() !== 'self') {
        const username = (thdCustomer?.username.charAt(0).toUpperCase()
          + thdCustomer?.username.slice(1).toLowerCase());
        setTitle(`${greetingType}, ${username}`);
      } else {
        setTitle(greetingType);
      }
    }
  }, [thdCustomer?.isLoggedIn]);

  return (title);
};

MyAccountMenuTitle.displayName = 'MyAccountMenuTitle';

export default MyAccountMenuTitle;
