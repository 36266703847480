/* eslint-disable max-len */
import React from 'react';
import DeliveryZipCodeContent from './DeliveryZipCodeContent';
import { defaultContent } from './DrawerContext';
import { isNestedMenuLevel } from '../../utils/headerUtils';

const ContentMenuDataQueryProvider = React.lazy(() => import(
  /* webpackChunkName: "ContentMenuDataQueryProvider" */
  '../dynamic-content/ContentMenuDataQueryProvider')
  // eslint-disable-next-line no-console
  .catch((error) => console.warn(`Error occurred while loading content menu data - ${error}`)));

const MyStoreContent = React.lazy(() => import(
  /* webpackChunkName: "MyStoreContent" */
  './MyStoreContent')
  // eslint-disable-next-line no-console
  .catch((error) => console.warn(`Error occurred while loading my store content - ${error}`)));

const MyAccountContent = React.lazy(() => import(
  /* webpackChunkName: "MyAccountContent" */
  './MyAccountContent/MyAccountContent')
  // eslint-disable-next-line no-console
  .catch((error) => console.warn(`Error occurred while loading my account content - ${error}`)));

const updateContentMenuState = (state, action) => {
  const {
    type, title, flyoutId, navigationId, navigationLevel, backtrackMenus = []
  } = action;
  const { navigationTracker } = state;

  const getNavigationTracker = () => {
    if (isNestedMenuLevel(navigationLevel)) {
      return [
        ...navigationTracker,
        ...backtrackMenus,
        {
          type, title, flyoutId, navigationId, navigationLevel
        }
      ];
    }

    return [
      {
        type, title, flyoutId, navigationId, navigationLevel
      }
    ];
  };

  return {
    name: type,
    position: 'right',
    title,
    flyoutId,
    navigationId,
    navigationLevel,
    navigationTracker: getNavigationTracker(),
    drawerContent: <ContentMenuDataQueryProvider />,
    open: true
  };
};

const drawerReducer = (state, action) => {
  switch (action?.type) {
  case 'Shop All':
  case 'Services':
  case 'DIY':
  case 'Menu':
    return updateContentMenuState(state, action);

  case 'Account': {
    return {
      name: action.type,
      position: 'right',
      title: action?.title,
      drawerContent: (<MyAccountContent />),
      open: true
    };
  }

  case 'MyStore': {
    return {
      name: action.type,
      title: 'My Store',
      position: 'left',
      drawerContent: (<MyStoreContent />),
      open: true
    };
  }

  case 'DeliveryZIP': {
    return {
      name: action.type,
      title: 'Delivery ZIP Code',
      position: 'left',
      drawerContent: (<DeliveryZipCodeContent />),
      open: true
    };
  }

  case 'backtrack': {
    const lastTwoTrackedMenus = -2;
    const navigationTrackerArray = Array.isArray(state.navigationTracker) ? state.navigationTracker : defaultContent().navigationTracker;
    const [secondToLast, lastElement] = navigationTrackerArray.slice(lastTwoTrackedMenus);

    if (!secondToLast) return defaultContent();

    return {
      name: secondToLast.type,
      position: 'right',
      title: secondToLast.title,
      flyoutId: secondToLast.flyoutId,
      navigationId: secondToLast.navigationId,
      navigationLevel: secondToLast.navigationLevel,
      navigationTracker: state.navigationTracker?.filter((item) => item.title !== lastElement.title) || defaultContent().navigationTracker,
      drawerContent: <ContentMenuDataQueryProvider />,
      open: true
    };
  }

  case 'clear': {
    const { position } = state;
    // keep state position so drawer doesn't glitch during close effect
    return defaultContent(position);
  }

  default: {
    return defaultContent();
  }
  }
};

export { drawerReducer };
