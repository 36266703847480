import React, { useEffect, useState } from 'react';
import { node, oneOf, bool } from 'prop-types';
import classNames from 'classnames';
import { headerEvent } from '../../events/header.events';

/**
 * This component will display a primary or secondary node based on the subscribed event's output.
 * Requires the event to output a data point to properly make a decision to switch nodes.
 *
 * At this time this components only supports one event and adapts to the {open} value.
 * This will need to change if other events output a different datapoint.
 */
const AdaptiveUIEventSubscriber = ({
  event = null,
  primaryNode = null,
  secondaryNode = null,
  mobileOnly = null
}) => {
  const [displaySecondary, setDisplaySecondary] = useState(false);

  useEffect(() => {
    if (!event) return;
    headerEvent.listen({
      eventName: event,
      onEvent: ({ output = {} }) => {
        const { open = false } = output;

        // adding a delay fixes Header's search from losing the cursor due to shift caused by switching nodes
        setTimeout(() => setDisplaySecondary(open), 60);
      }
    });
  }, []);

  const wrapperClasses = classNames({
    'sui-hidden lg:sui-contents': mobileOnly === false,
    'sui-contents lg:sui-hidden': mobileOnly === true
  });

  const primaryClasses = classNames(
    'lg:sui-block lg:sui-visible lg:sui-opacity-100 lg:sui-static',
    {
      'sui-visible': !displaySecondary,
      'sui-opacity-0 sui-invisible sui-absolute': displaySecondary,
    });

  const secondaryClasses = classNames({
    'sui-opacity-0 sui-invisible sui-absolute': !displaySecondary,
    'sui-visible': displaySecondary,
    'lg:sui-hidden': displaySecondary,
  });

  return (
    <div
      data-testid="header-adaptive-ui-container"
      className={wrapperClasses}
    >
      <div
        data-testid="header-adaptive-ui-primary"
        className={primaryClasses}
      >
        {primaryNode}
      </div>
      <div
        data-testid="header-adaptive-ui-secondary"
        className={secondaryClasses}
      >
        {secondaryNode}
      </div>
    </div>
  );
};

AdaptiveUIEventSubscriber.displayName = 'AdaptiveUIEventSubscriber';

AdaptiveUIEventSubscriber.propTypes = {
  /** events supported by this component */
  event: oneOf(['typeahead.suggestions-menu-open-close']),
  primaryNode: node,
  secondaryNode: node,
  mobileOnly: bool
};

export { AdaptiveUIEventSubscriber };
