import { HOST_CONSTANTS } from '../../../constants';

const validateEmail = (input) => {
  if (!input) {
    return { message: 'Required' };
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(input)) {
    return { message: 'Invalid email address' };
  }

  return null;
};

export const emailPreferencesApi = {
  update: async ({ emailInput, zipcodeInput }) => {

    const validationResponse = validateEmail(emailInput);
    if (validationResponse) {
      return { statusMsg: 'error', msg: validationResponse.message };
    }

    const { DEFAULT_HOST } = HOST_CONSTANTS;

    const submitUrl = `${DEFAULT_HOST}/cdg/epc/v3/email/update`;
    const requestBody = {
      subscriptions: [{ type: 'PROMOTIONS', status: 'Y' }],
      emailAddress: emailInput,
      zip5: zipcodeInput,
      sourceId: 'FOOTER_EMAIL_SIGNUP'
    };

    try {
      const response = await fetch(submitUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-client-id': 'FOOTER_EMAIL_SIGNUP'
        },
        body: JSON.stringify(requestBody)
      });

      const data = await response.json();
      const { emailStatus, subscriptions = [], errors } = data;

      if (errors) {
        return { statusMsg: 'error', msg: 'Error occurred on the email signup' };
      }

      const isSubscribed = subscriptions?.some((item) => item?.type === 'PROMOTIONS' && item?.status === 'Y');

      if (emailStatus === 'NORMAL' && isSubscribed) {
        return {
          statusMsg: 'success',
          msg: 'Thank you for signing up. Your first email should arrive shortly.'
        };
      }

      return { statusMsg: 'error', msg: 'Unable to signup at this time' };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Email preferences update API Error:', error);
      return { statusMsg: 'error', msg: 'Error occurred on the email signup' };
    }
  }
};
