/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useEffect, useImperativeHandle, forwardRef, useRef, useState, Suspense
} from 'react';
import {
  number, string, func, bool, shape, node, arrayOf
} from 'prop-types';
import classNames from 'classnames';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import useClickAway from '../hooks/useClickAway';
import { CUSTOM_SUBMIT_EVENTS, handleEvent } from '../utils/typeahed-element-events';
import { SearchField } from './SearchField';
import { Results } from './Results';
import HTMLElementType from '../utils/HTMLElementType';
import { SuggestionsMenu } from './SuggestionsMenu';
import { shouldBypassFocusOut, isValidAnchorElement } from '../utils/typeahead-utils';
import { SuggestionsMenuFooter } from './SuggestionsMenuFooter';
import { SuggestionsMenuTitle, CLEAR_ALL_BUTTON_ID } from './SuggestionsMenuTitle';
import { useComboboxContext } from './ComboboxContext';
import { ComboboxProvider } from './ComboboxProvider';
import yieldToMain from '../utils/yieldToMain';

const RecentlyViewedTypeaheadWrapper = React.lazy(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "RecentlyViewedTypeaheadWrapper" */
  '@thd-olt-component-react/typeahead-modules')
  .then(module => ({ default: module.RecentlyViewedTypeaheadWrapper }))
  // eslint-disable-next-line no-console
  .catch((error) => console.warn(`Error occurred while loading Recently Viewed TA - ${error}`)));

const Typeahead = forwardRef(({
  onSubmit,
  onFocusOut,
  onInputChange,
  onInputFocus,
  onListItemRemoval,
  clearSearchResults,
  searchFieldProps,
  suggestionsMenuProps,
  resultsListProps,
  results,
}, ref) => {
  const [recentlyViewedState, setRecentlyViewedState] = useState({ hasData: false });

  const typeaheadContainerRef = useRef(null);
  const userSearchTermRef = useRef('');
  const searchInputRef = useRef(null);
  const typeaheadActiveRef = useRef(false);

  const { comboboxMotionState, comboboxMotionDispatch } = useComboboxContext();

  const {
    formProps = {},
    inputProps = {}
  } = searchFieldProps;
  const {
    open,
    anchorElement,
    fullScreenHeight,
    fullScreenWidth,
    suggestionsMenuHeader,
    suggestionsMenuFooter,
    backdropOffset,
    isMobile,
    recentlyViewedOptions
  } = suggestionsMenuProps;
  const { isRecentlyViewedEnabled, hideRecentlyViewed } = recentlyViewedOptions || {};
  const {
    numberOfResultsToShow = 6,
    showDeleteListButton = false
  } = resultsListProps;

  const openMenu = isValidAnchorElement(anchorElement) && open;

  const resultsSubset = results?.slice(0, numberOfResultsToShow) || [];
  const hasSearchResults = resultsSubset.length > 0;
  const isVisibleSuggestionsMenu = typeaheadActiveRef.current
      && (hasSearchResults || recentlyViewedState?.hasData);

  const handleClickAway = async (event) => {
    if (!typeaheadActiveRef.current) return;

    const { id, parentElement } = event?.target || {};
    const ids = [id, parentElement?.id];

    await yieldToMain();

    comboboxMotionDispatch({ type: 'SearchClear' });

    if (shouldBypassFocusOut(ids)) return;

    typeaheadActiveRef.current = false;
    if (onFocusOut) onFocusOut();
  };

  const handleOnSubmit = (event, customEvent) => {
    handleEvent.onSubmit(event,
      {
        ...customEvent,
        url: customEvent?.url || comboboxMotionState?.active?.item?.link,
        results: customEvent?.results || comboboxMotionState?.list,
        userSearchTerm: userSearchTermRef.current
      },
      onSubmit
    );
  };

  const handleOnFocus = (event) => {
    typeaheadActiveRef.current = true;
    handleEvent.onFocus(event, onInputFocus);
  };

  const handleOnKeyDown = async (event) => {
    if (!openMenu) return;
    const key = event.key;

    if (key === 'ArrowUp') {
      event.preventDefault();
    }
    await yieldToMain();
    if (key?.length > 1) {
      comboboxMotionDispatch({ type: key, list: resultsSubset });
    }
  };

  const handleOnChange = (event) => {
    userSearchTermRef.current = event?.target?.value;
    handleEvent.onChange(event, onInputChange);
  };

  const handleSearchIconClick = (event) => {
    handleOnSubmit(event, {
      type: CUSTOM_SUBMIT_EVENTS.SEARCH_ICON_CLICK,
      selectedSearchTerm: searchInputRef?.current?.value
    });
  };

  const handleFormOnSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleOnSubmit(event, {
      type: CUSTOM_SUBMIT_EVENTS.ENTER_KEY,
      selectedSearchTerm: searchInputRef.current.value
    });
  };

  const handleSearchClear = (event) => {
    comboboxMotionDispatch({ type: 'SearchClear' });
    userSearchTermRef.current = '';
    searchInputRef.current.value = '';
    searchInputRef.current.focus();
  };

  const handleRVDataLoaded = ({ hasData }) => {
    if (hasData) {
      setRecentlyViewedState({ hasData: true });
    }
  };

  const handleClearAll = () => {
    if (recentlyViewedState.hasData) {
      suggestionsMenuHeader.onClearAllClicked({ bypassFocusOut: true });
      clearSearchResults();
    } else {
      suggestionsMenuHeader.onClearAllClicked({ bypassFocusOut: false });
      clearSearchResults();
    }
  };

  useEffect(() => {
    // prevents glitch effect in chrome that caused input's focus event
    // to execute when it's not the target element.
    // scenario: input is in focus -> click outside of the window -> then click back inside the window.
    const handleBlur = () => {
      if (document.activeElement === searchInputRef.current) {
        // eslint-disable-next-line no-unused-expressions
        searchInputRef.current?.blur();
      }
    };

    window.addEventListener('blur', handleBlur);
    return () => window.removeEventListener('blur', handleBlur);
  }, []);

  useEffect(() => {
    if (comboboxMotionState.active) {
      comboboxMotionDispatch({ type: 'SearchClear' });
    }
  }, [results]);

  useEffect(() => {
    if (comboboxMotionState?.active?.item?.title) {
      searchInputRef.current.value = comboboxMotionState.active.item.title;
    }
  }, [comboboxMotionState.active]);

  useClickAway(typeaheadContainerRef, handleClickAway);

  useImperativeHandle(ref, () => typeaheadContainerRef.current);

  return (
    <div
      id="typeahead-container"
      data-testid="typeahead-container"
      ref={typeaheadContainerRef}
      data-component="Typeahead"
    >
      <SearchField
        ref={searchInputRef}
        onSearchIconClick={handleSearchIconClick}
        onClearIconClick={handleSearchClear}
        formProps={{
          ...formProps,
          onSubmit: handleFormOnSubmit
        }}
        inputProps={{
          ...inputProps,
          id: 'typeahead-search-field-input',
          'data-testid': 'typeahead-search-field-input',
          role: 'combobox',
          'aria-autocomplete': 'list',
          'aria-controls': 'results-listbox',
          'aria-owns': 'results-listbox',
          'aria-expanded': openMenu && isVisibleSuggestionsMenu,
          onChange: handleOnChange,
          onFocus: handleOnFocus,
          onKeyDown: handleOnKeyDown,
        }}
        customClasses={searchFieldProps?.customClasses}
      />
      <SuggestionsMenu
        open={openMenu}
        anchorElement={anchorElement}
        fullScreenHeight={fullScreenHeight}
        fullScreenWidth={fullScreenWidth}
        onClickAway={handleClickAway}
        backdropOffset={backdropOffset}
        isVisible={isVisibleSuggestionsMenu}
      >
        {
          isRecentlyViewedEnabled && (
            <ErrorBoundary id="recently-viewed-ta" name="recently-viewed-typeahead">
              <Suspense fallback={null}>
                <RecentlyViewedTypeaheadWrapper
                  isMobile={isMobile}
                  hideRecentlyViewed={hideRecentlyViewed}
                  onRVDataLoaded={handleRVDataLoaded}
                />
              </Suspense>
            </ErrorBoundary>
          )
        }
        {suggestionsMenuHeader && hasSearchResults && (
          <SuggestionsMenuTitle
            headerTitle={suggestionsMenuHeader?.headerTitle}
            showClearAllButton={suggestionsMenuHeader?.showClearAllButton}
            handleClearAll={handleClearAll}
          />
        )}
        <Results
          searchInputElement={searchInputRef.current}
          showDeleteListButton={showDeleteListButton}
          formattedResults={resultsSubset}
          onSubmit={handleOnSubmit}
          onListItemRemoval={onListItemRemoval}
        />
        {suggestionsMenuFooter && <SuggestionsMenuFooter footer={suggestionsMenuFooter} />}
      </SuggestionsMenu>
    </div>
  );
});

Typeahead.displayName = 'Typeahead';

Typeahead.propTypes = {
  onSubmit: func,
  onListItemRemoval: func,
  clearSearchResults: func,
  onFocusOut: func,
  onInputChange: func,
  onInputFocus: func,
  searchFieldProps: shape({
    formProps: shape({}),
    inputProps: shape({}),
    showSearchClearIcon: bool
  }),
  suggestionsMenuProps: shape({
    open: bool,
    anchorElement: HTMLElementType,
    backdropOffset: shape({
      top: number,
    }),
    fullScreenHeight: bool,
    fullScreenWidth: bool,
    suggestionsMenuHeader: shape({
      headerTitle: string,
      showClearAllButton: bool,
      onClearAllClicked: func
    }),
    suggestionsMenuFooter: node,
  }),
  resultsListProps: shape({
    numberOfResultsToShow: number,
    showDeleteListButton: bool
  }),
  results: arrayOf(shape({
    link: string,
    title: string,
    categories: arrayOf(shape({
      cLink: string,
      cTitle: string,
    }))
  })),
};

Typeahead.defaultProps = {
  onSubmit: () => { },
  onListItemRemoval: () => { },
  clearSearchResults: () => { },
  onFocusOut: () => { },
  onInputChange: () => { },
  onInputFocus: () => { },
  searchFieldProps: shape({
    formProps: {},
    inputProps: {},
    showSearchClearIcon: true
  }),
  suggestionsMenuProps: shape({
    open: false,
    anchorElement: undefined,
    backdropOffset: shape({
      top: undefined,
    }),
    fullScreenHeight: false,
    fullScreenWidth: false,
    suggestionsMenuHeader: undefined,
    suggestionsMenuFooter: undefined,
  }),
  resultsListProps: {
    numberOfResultsToShow: 6,
    showDeleteListButton: false
  },
  results: null
};

const withProvider = () => forwardRef((props, ref) => (
  <ComboboxProvider>
    <Typeahead {...props} ref={ref} />
  </ComboboxProvider>
));

const TypeaheadWithProvider = withProvider();
export { TypeaheadWithProvider as Typeahead };
