import { useBreakpoint } from '@one-thd/sui-atomic-components';
import { MenuLevelDictionary } from '../components/dynamic-content/ContentMenuDictionary';
import { CONSTANTS, HOST_CONSTANTS } from '../constants';

const { TOPHAT_HEIGHT_OFFSET } = CONSTANTS;

export const ownerDocument = (node) => (node && node.ownerDocument) || document;

export const useMobileBreakpoint = () => {
  const { lesser: isMobile } = useBreakpoint('lg');
  return isMobile;
};

export const useDesktopBreakpoint = () => {
  const { greater: isDesktop } = useBreakpoint('lg');
  return isDesktop;
};

export default function setRef(ref, value) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    // eslint-disable-next-line no-param-reassign
    ref.current = value;
  }
}

export const isNestedMenuLevel = (navLevel) => {
  return ([
    MenuLevelDictionary.LEVEL_2,
    MenuLevelDictionary.LEVEL_3,
    MenuLevelDictionary.LEVEL_4,
    MenuLevelDictionary.LEVEL_5,
  ].includes(navLevel));
};

export const getGraphqlHost = () => {
  const currentHost = typeof window !== 'undefined' ? window?.location?.host?.split('.')[0] : null;
  const { ENV, GRAPHQL_HOST, DEFAULT_HOST } = HOST_CONSTANTS;

  switch (currentHost) {
  case ENV.DEV: {
    return GRAPHQL_HOST.DEV;
  }
  case ENV.STAGE: {
    return GRAPHQL_HOST.STAGE;
  }
  case ENV.LOCAL: {
    return DEFAULT_HOST;
  }
  default: {
    return GRAPHQL_HOST.PROD;
  }
  }
};
export const getTopHatOffset = () => {
  const scrollOffset = typeof window !== 'undefined' ? window?.pageYOffset : 0;
  return scrollOffset < TOPHAT_HEIGHT_OFFSET ? (TOPHAT_HEIGHT_OFFSET - scrollOffset) : 0;
};
