import { useEffect, useState } from 'react';
import THDStorageUtils, { LOCAL_STORAGE } from '@thd-olt-global/thd-storage-utils';
import THDCustomer from '@thd-olt-global/thd-customer';
import { headerEvent } from '../events/header.events';
import { CONSTANTS, HOST_CONSTANTS } from '../constants';

const CONTACT_CENTER_STORAGE_KEY = 'header_contact_center_storage_key';

const MEMBER_NUM = '1-866-333-3551';

const MEMBER_SMS = '78465';
const ELITE_SMS = '86343';
const VIP_SMS = '33955';

const SMS_MAP = {
  '1-866-333-3551': MEMBER_SMS,
  '1-888-948-3375': ELITE_SMS,
  '1-800-525-5000': VIP_SMS,
};

const DEFAULT_CONTACT_CENTER = { number: MEMBER_NUM, smsNumber: MEMBER_SMS };

const useContactCenter = (props = {}) => {
  const { skip = false } = props;

  const [contactCenter, setContactCenter] = useState(skip ? {} : DEFAULT_CONTACT_CENTER);
  const { DEFAULT_HOST } = HOST_CONSTANTS;

  const contactNumberApi = {
    fetch: async () => {
      const tmxProfileId = window?.THD_ACCOUNT_LIB?.TMX?.getTMXsessionId();
      if (!tmxProfileId) {
        return DEFAULT_CONTACT_CENTER;
      }

      try {
        const response = await fetch(
          `${DEFAULT_HOST}/b2b/user/account/${THDCustomer?.userID}/custInfo/contactNumber`,
          {
            headers: {
              'Content-Type': 'application/json',
              channelId: '1',
              TMXProfileID: tmxProfileId,
            },
          },
        );
        const result = await response.json();

        const { contactCenterNumber = MEMBER_NUM } = result;
        const smsNumber = SMS_MAP[contactCenterNumber] || MEMBER_SMS;

        return { number: contactCenterNumber, smsNumber };

      } catch (error) {
        return DEFAULT_CONTACT_CENTER;
      }
    }
  };

  const contactCenterStorage = {
    get: () => {
      try {
        const data = THDStorageUtils.get(LOCAL_STORAGE, { key: CONTACT_CENTER_STORAGE_KEY });
        if (data === 'undefined' || !data) {
          return null;
        }
        return JSON.parse(data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error({
          message: `Error occurred while accessing local storage - ${CONTACT_CENTER_STORAGE_KEY}`,
          error
        });
        return null;
      }
    },
    update: (data) => {
      try {
        THDStorageUtils.set(LOCAL_STORAGE, {
          key: CONTACT_CENTER_STORAGE_KEY,
          value: JSON.stringify(data),
          expiration: 'day',
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error({
          message: `Error occurred while updating local storage - ${CONTACT_CENTER_STORAGE_KEY}`,
          error
        });
      }
    }
  };

  const contactCenterProcess = async () => {
    if (skip) return null;

    const storeData = contactCenterStorage.get();
    if (storeData) {
      setContactCenter(storeData);
      return null;
    }

    const data = await contactNumberApi.fetch();
    contactCenterStorage.update(data);
    setContactCenter(data);
    return null;
  };

  useEffect(() => {
    contactCenterProcess();
  }, []);

  useEffect(() => {
    if (skip) return;

    headerEvent.publish({
      eventName: CONSTANTS.HEADER_CONTACT_CENTER_EVENT,
      payload: contactCenter,
      uselifecycle: true
    });

  }, [contactCenter?.number, contactCenter?.smsNumber]);

  return [contactCenter];
};

export default useContactCenter;
