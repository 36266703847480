import { useState, useEffect } from 'react';
import { useUserContext } from '../../generic-ui/UserContext';

const MyAccountButtonLabel = () => {
  const { storefront, thdCustomer } = useUserContext();
  const { buyerOrg: companyName } = storefront || {};
  const [label, setLabel] = useState('Log In');
  const getDisplayName = (username) => {
    if (storefront) {
      if (!username) return 'Me';
      return username;
    }

    if (!username
        || username.toLowerCase() === 'self'
        || username.length > 10) {
      return 'Me';
    }

    return username.charAt(0).toUpperCase() + username.slice(1).toLowerCase();
  };

  useEffect(() => {
    if (storefront) {
      setLabel(getDisplayName(companyName));
      return;
    }

    if (thdCustomer?.isLoggedIn) {
      setLabel(getDisplayName(thdCustomer?.username));
    }
  }, [companyName]);

  return (label);
};

MyAccountButtonLabel.displayName = 'MyAccountButtonLabel';

export default MyAccountButtonLabel;
