import { CLEAR_ALL_BUTTON_ID } from '../components/SuggestionsMenuTitle';
import { SEARCH_CLEAR_BUTTON_ID } from '../components/SearchField';

const BYPASS_FOCUS_OUT_ELEMENTS = Object.freeze({
  CLEAR_ALL_BUTTON_ID,
  SEARCH_CLEAR_BUTTON_ID
});

export const isValidAnchorElement = (anchorElement) => {
  return typeof anchorElement?.getBoundingClientRect === 'function';
};

export const shouldBypassFocusOut = (ids = []) => {
  if (!Array.isArray(ids)) return false;

  return ids.some((val) => Object.values(BYPASS_FOCUS_OUT_ELEMENTS).includes(val));
};
