import React, { useEffect } from 'react';
import { node, oneOf } from 'prop-types';
import { footerAnalytics } from '../../events/analytics.listener';
import { FooterContextProvider } from './FooterContextProvider';

const FooterController = ({ children = null, customerType = 'default' }) => {

  useEffect(() => {
    footerAnalytics.listen();
  }, []);

  return (
    <FooterContextProvider customerType={customerType}>
      {children}
    </FooterContextProvider>
  );
};
FooterController.displayName = 'FooterController';

FooterController.propTypes = {
  children: node,
  customerType: oneOf(['default', 'b2b'])
};

export { FooterController };