// Header UI utility functions
import { CONSTANTS } from '../../constants';
import { headerEvent } from '../../events/header.events';

/** Header Logo onClick to publish analytics event */
export const handleLogoClick = () => {
  headerEvent.publish({
    eventName: 'header.header-click',
    payload: { clickPath: 'logo' }
  });
};

const extractStoreHours = (hours, isFormatted) => {
  return Object.values(hours).map((time) => {
    if (isFormatted) {
      return `${time.toString().split(':')[0] % 12}${time.toString().split(':')[0] >= 12 ? 'PM' : 'AM'}`;
    }
    return Number(time.toString().split(':')[0]);
  });
};

const nextAvailableStoreHours = (storeHours) => {
  if (!storeHours) return null;

  const currentTime = new Date();
  const today = currentTime.toLocaleString('en-us', { weekday: 'long' }).toLocaleLowerCase();
  const allStoreHours = Object.entries(storeHours);
  const todaysStoreHours = allStoreHours.findIndex(([day]) => day === today);
  const storeHoursFromToday = allStoreHours.slice(todaysStoreHours)
    .concat(allStoreHours.slice(0, todaysStoreHours));

  return storeHoursFromToday.find(([day, hours]) => {
    const [openTime, closeTime] = extractStoreHours(hours);

    let isBeforeStoreOpeningHours = openTime >= currentTime.getHours();
    let isAfterStoreClosingHours = closeTime <= currentTime.getHours();
    let isStoreCurrentlyOpen = !isBeforeStoreOpeningHours && !isAfterStoreClosingHours;

    return (day === today && isBeforeStoreOpeningHours) // If its early in the morning before the store opens
      || (isStoreCurrentlyOpen) // If the store is open currently
      || (day !== today && openTime) // Is there a coming up store opening hours
      || (openTime === closeTime); // Is store Holiday
  });
};

export const findStoreHours = (storeHours) => {
  if (!storeHours) {
    return [CONSTANTS.STORE.CLOSED, '...'];
  }

  const currentTime = new Date();
  const nextAvailableHours = nextAvailableStoreHours(storeHours);

  if (nextAvailableHours) {
    const [day, hours] = nextAvailableHours;
    const [openTime, closeTime] = extractStoreHours(hours);

    if (openTime === closeTime) {
      return [CONSTANTS.STORE.CLOSED, CONSTANTS.STORE.HOLIDAY];
    }
    const [formattedOpenTime, formattedCloseTime] = extractStoreHours(hours, true);

    const currentHour = currentTime.getHours();
    const isStoreCurrentlyOpen = openTime <= currentHour && closeTime > currentHour;

    if (!isStoreCurrentlyOpen) {
      return [CONSTANTS.STORE.CLOSED, formattedOpenTime];
    }

    if (isStoreCurrentlyOpen) {
      return [CONSTANTS.STORE.OPEN, formattedCloseTime];
    }
  }

  return [CONSTANTS.STORE.CLOSED, CONSTANTS.STORE.TEMP_CLOSED];
};
