export const CONSTANTS = {
  // eslint-disable-next-line
  HEADER_VERSION: typeof __HEADER_VERSION__ !== 'undefined' ? __HEADER_VERSION__ : '',
  STORE: {
    OPEN: 'success',
    HOLIDAY: 'CLOSED',
    CLOSED: 'danger',
    TEMP_CLOSED: '00',
  },
  SEARCH_HISTORY_STORAGE_KEY: 'header_search_history',
  SEARCH_INPUT_VALUE: 'header_search_last_input_value',
  THD_PERSIST_COOKIE_KEY: ' THD_PERSIST',
  THD_PERSIST_STOREFRONT: 'THD_PERSIST_STOREFRONT',
  BLINDS_CART_COUNT_COOKIE_KEY: ' Blinds_ComCartSamplesCount',
  HEADER_CONTACT_CENTER_EVENT: 'header.contact_center_event',
  TYPEAHEAD_DATA_SOURCE: {
    SEARCH_HISTORY: 'search-history',
    TA_V2_SEARCH_API: 'typeahead-v2-search-api'
  },
  TOPHAT_HEIGHT_OFFSET: 32
};

export const B2B_CONSTANTS = {
  ...CONSTANTS,
  TITLES: {
    THD_LOGO_ALT_TEXT: 'The Home Depot Pro Logo',
  },
  PRO_XTRA_PERKS_SESSION_KEY: 'isEnrolledInPerksProgram',
};

export const B2C_CONSTANTS = {
  ...CONSTANTS,
  TITLES: {
    THD_LOGO_ALT_TEXT: 'The Home Depot Logo',
  }
};

export const gpsPermissions = Object.freeze({
  'gps access granted': 'granted',
  'gps access denied': 'denied',
  'gps timeout': 'timeout',
});

export const HOST_CONSTANTS = {
  // eslint-disable-next-line
  DEFAULT_HOST: typeof __DEFAULT_HOST__ !== 'undefined' ?  __DEFAULT_HOST__ : '',
  GRAPHQL_HOST: {
    DEV: 'https://apionline-dev.homedepotdev.com',
    STAGE: 'https://apionline-stage.homedepotdev.com',
    PROD: 'https://apionline.homedepot.com'
  },
  ENV: {
    DEV: 'hd-qa71',
    STAGE: 'hd-qa74',
    LOCAL: 'localhost'
  }
};

export const candidatesSelector = [
  'input',
  'select',
  'textarea',
  'a[href]',
  'button',
  '[tabindex]',
  'audio[controls]',
  'video[controls]',
  '[contenteditable]:not([contenteditable="false"])',
].join(',');
