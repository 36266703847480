import { useState, useMemo } from 'react';
import { handleEvent } from '../utils/typeahed-element-events';

/**
 * `useAsync` extracts behavior related to asynchronous searches.
 *
 * - debounce `onInputChange` to allow for a complete search text before executing `onSearch`
 * - debounce `onSubmit` for protection against `{enter}` key or click spams
 *
 * Executes `onSearch` callback when Typeahead's input is in focus
 */
export const useAsync = (props) => {
  const {
    onSearch,
    onSubmit,
    onFocusOut,
    onListItemRemoval,
    onInputFocus,
    ...otherProps
  } = props;
  const [results, setResults] = useState(null);

  const executeSearchRequest = async (event, searchText) => {
    if (typeof onSearch !== 'function') return;

    const data = await Promise.resolve(onSearch(searchText));
    if (data) setResults(data);
  };

  const handleOnFocusOut = () => {
    setResults(null);
    if (onFocusOut) onFocusOut();
  };

  const handleOnListItemRemoval = (userSearchTerm, selectedTextToRemove) => {
    if (typeof onListItemRemoval !== 'function') return;
    onListItemRemoval(userSearchTerm, selectedTextToRemove);
    executeSearchRequest(null, userSearchTerm);
  };
    /**
   * handlers
   */
  const memoizedOnInputChange = useMemo(() => (event) => handleEvent.onChange(event, executeSearchRequest, true), []);

  const memoizedOnInputFocus = useMemo(() => (event) => {
    executeSearchRequest(event, event?.target?.value);
    handleEvent.onFocus(event, onInputFocus);
  }, [onInputFocus]);

  return {
    ...otherProps,
    onSubmit: (event, customEvent) => handleEvent.onSubmit(event, customEvent, onSubmit, true),
    onFocusOut: handleOnFocusOut,
    onInputChange: memoizedOnInputChange,
    onInputFocus: memoizedOnInputFocus,
    onListItemRemoval: handleOnListItemRemoval,
    clearSearchResults: () => setResults(null),
    results
  };
};
