import React, { useEffect, useState, useRef } from 'react';
import {
  LoadingButton, Input,
  FormController,
  FormStatusMessage
} from '@one-thd/sui-atomic-components';
import { emailPreferencesApi } from './api/emailPreferences';

const FooterEmail = () => {
  const localizerRef = useRef(null);
  const emailInputRef = useRef(null);

  const [msgStatus, setMsgStatus] = useState({ statusMsg: null, msg: '' });
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const activeElId = document?.activeElement?.id;
    setLoading(true);
    // Added to protect against auto email opt-ins
    // Ensures users is actively interacting with the form
    if (activeElId !== 'footer-email-input' && activeElId !== 'footer-email-button') return;

    const emailInput = emailInputRef.current?.value;
    const zipDetails = localizerRef.current?.getDeliveryZipcodeDetails();

    const response = await emailPreferencesApi.update({ emailInput, zipcodeInput: zipDetails?.zipcode });
    setLoading(false);
    const { statusMsg, msg } = response;
    setMsgStatus({ statusMsg, msg });
  };

  const emailWrapperClasses = 'sui-h-auto sui-pt-2 sui-flex sui-flex-col sui-justify-between';

  useEffect(() => {
    import('Localizer')
      .then((localizer) => { localizerRef.current = localizer; })
      // eslint-disable-next-line no-console
      .catch((error) => console.warn(`Error occurred while loading Localizer - ${error}`));
  }, []);

  return (
    <div className={emailWrapperClasses}>
      <div className="sui-text-base">Get $5 off when you sign up for emails with savings
        and tips.
      </div>
      <form id="footer-email-form" onSubmit={handleSubmit} autoComplete="off">
        <div className="sui-flex sui-flex-row sui-items-start">
          <FormController fullWidth required status={msgStatus.statusMsg}>
            <Input
              inputRef={emailInputRef}
              id="footer-email-input"
              type="email"
              aria-describedby="footer-email-input-message"
              placeholder="Enter Email Address"
              autoComplete="off"
              inputAttributes={{
                'data-testid': 'footer-email-input'
              }}
            />
            <FormStatusMessage id="footer-email-input-message" data-testid="footer-email-input-message">
              {msgStatus.msg}
            </FormStatusMessage>
          </FormController>
          <LoadingButton
            type="button"
            id="footer-email-button"
            data-testid="footer-email-button"
            onClick={handleSubmit}
            loading={loading}
          >
            GO
          </LoadingButton>
        </div>
      </form>
    </div>
  );
};

FooterEmail.displayName = 'FooterEmail';

export { FooterEmail };
