import React, { forwardRef } from 'react';
import { useAsync } from '../hooks/useAsync';
import { Typeahead } from './Typeahead';

/**
 * `TypeaheadAsync` adds extra functionality by invoking `useAsync` to enhance `Typeahead`.
 *
 * The same props from `Typeahead` will be leveraged along with `onSearch`
 *
 * debounce added to `onInputChange` and `onSubmit`
 *
 * Executes `onSearch` callback when Typeahead's input is in focus
 */
const TypeaheadAsync = forwardRef((props, ref) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Typeahead {...useAsync(props)} ref={ref} />
  );
});

export { TypeaheadAsync };