export const headerEvent = {
  publish: ({ eventName, payload = {}, uselifecycle = false }) => {
    if (!window?.LIFE_CYCLE_EVENT_BUS) {
      // eslint-disable-next-line no-console
      console.error(`life cycle event bus was not found, unable to publish event ${eventName}`);
      return;
    }
    if (!uselifecycle) {
      window.LIFE_CYCLE_EVENT_BUS.trigger(eventName, payload);
    } else {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(eventName, payload);
    }

  },
  listen: ({ eventName, onEvent, uselifecycle = false }) => {
    if (!window?.LIFE_CYCLE_EVENT_BUS) {
      // eslint-disable-next-line no-console
      console.error(`life cycle event bus was not found, unable to setup listener for ${eventName}`);
      return;
    }
    if (!uselifecycle) {
      window.LIFE_CYCLE_EVENT_BUS.on(eventName, onEvent);
    } else {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on(eventName, onEvent);
    }
  },
  off: ({ eventName, onEvent, uselifecycle = false }) => {
    if (!window?.LIFE_CYCLE_EVENT_BUS) {
      // eslint-disable-next-line no-console
      console.error(`life cycle event bus was not found, unable to unsubscribe listener for ${eventName}`);
      return;
    }
    if (!uselifecycle) {
      window.LIFE_CYCLE_EVENT_BUS.off(eventName, onEvent);
    } else {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.off(eventName, onEvent);
    }

  }
};