import React, { useState, useEffect } from 'react';
import { Typography } from '@one-thd/sui-atomic-components';
import { Cart } from '@one-thd/sui-icons';
import THDStorageUtils, { COOKIE_STORAGE } from '@thd-olt-global/thd-storage-utils';
import { HeaderIconTextButton } from '../generic-ui/HeaderIconTextButton';
import { CONSTANTS, HOST_CONSTANTS } from '../../constants';
import { headerEvent } from '../../events/header.events';
import { useThemeContext } from '../generic-ui/ThemeContext';
import { useUserContext } from '../generic-ui/UserContext';

const CartIcon = () => {
  const { inverseTheme } = useThemeContext();
  const { storefront } = useUserContext();

  const [cartCount, setCartCount] = useState(0);

  const { DEFAULT_HOST } = HOST_CONSTANTS;

  const cartStorage = {
    getCount: () => {
      let miniCartCount = 0;
      let blindsCartCount = 0;
      try {
        const cookieKey = storefront ? CONSTANTS.THD_PERSIST_STOREFRONT : CONSTANTS.THD_PERSIST_COOKIE_KEY;
        const decodedCookie = THDStorageUtils.get(COOKIE_STORAGE, { key: cookieKey });

        if (decodedCookie && decodedCookie.includes('C6')) {
          const minicartCrumbStartIndex = decodedCookie.indexOf('C6=') + 3;
          const minicartCrumbEndIndex = decodedCookie.indexOf('}:;', minicartCrumbStartIndex) + 1;
          const thdMiniCartString = decodedCookie.substring(minicartCrumbStartIndex, minicartCrumbEndIndex) || '{}';
          miniCartCount = Number(JSON.parse(thdMiniCartString).I1);
        }
        blindsCartCount = Number(
          THDStorageUtils.get(
            COOKIE_STORAGE, { key: CONSTANTS.BLINDS_CART_COUNT_COOKIE_KEY }
          ).slice(0, -2)
        ) || 0;

        return miniCartCount + blindsCartCount;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error({
          message: `Error occurred while accessing cookie ${CONSTANTS.THD_PERSIST_COOKIE_KEY}`,
          error
        });
        return 0;
      }
    }
  };

  function updateCartCount(newCount) {
    if (newCount) {
      setCartCount(newCount);
      return;
    }
    setCartCount(cartStorage.getCount());
  }

  const handleCartClick = () => {
    headerEvent.publish({
      eventName: 'header.header-click',
      payload: { clickPath: 'cart' }
    });
  };

  useEffect(() => {
    updateCartCount();
    headerEvent.listen({
      eventName: 'add-to-cart.success',
      onEvent: (response) => updateCartCount(response?.output?.CartModel?.totalItemCount)
    });
  }, [storefront]);

  const cartCountRootClasses = 'sui-absolute sui-ml-5 sui-mb-1px sui-bottom-2 lg:sui-bottom-8';

  const cartCountClasses = [
    'sui-bg-brand sui-text-center sui-flex sui-items-center',
    'sui-justify-center sui-h-5 sui-w-8'
  ].join(' ');

  return (
    <div className="sui-flex sui-relative">
      <div className="sui-mr-3 sui-ml-1px sui-w-6 xl:sui-w-11 sui-flex sui-justify-center">
        <HeaderIconTextButton
          label="Cart"
          href={`${DEFAULT_HOST}/cart`}
          icon={Cart}
          textColor={inverseTheme ? 'inverse' : 'primary'}
          iconSize="regular"
          iconColor={inverseTheme ? 'inverse' : 'primary'}
          onClick={handleCartClick}
          mobileHideText
          data-testid="header-cart-icon"
          data-component="HeaderCartIcon"
          aria-label="Click to redirect to view my cart page"
        >
          {cartCount > 0
            && (
              <div className={cartCountRootClasses} data-testid="header-cart-count">
                <div className={cartCountClasses}>
                  <Typography color="inverse" variant="body-xs" weight="bold">
                    {cartCount > 99 ? '99+' : cartCount}
                  </Typography>
                </div>
              </div>
            )}
        </HeaderIconTextButton>
      </div>
    </div>
  );
};

export { CartIcon };
