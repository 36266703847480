import React from 'react';
import { CardCvv } from '@one-thd/sui-icons';
import { headerEvent } from '../../events/header.events';
import { HeaderIconTextButton } from '../generic-ui/HeaderIconTextButton';

const headerQuickAccessClasses = 'sui-hidden lg:sui-flex lg:sui-h-9 sui-pt-3';
const buttonWrapperClasses = 'sui-items-center sui-flex sui-justify-end sui-ml-auto sui-mr-6 sui-gap-6';

const HeaderQuickAccess = () => {
  const handleOnRedirect = (path, url) => {
    headerEvent.publish({
      eventName: 'header.header-click',
      payload: { clickPath: path?.toLowerCase() }
    });
    window.location.assign(url);
  };

  return (
    <div className={headerQuickAccessClasses} data-testid="header-b2c-credit-service">
      <div className={buttonWrapperClasses}>
        <HeaderIconTextButton
          data-testid="header-b2c-credit-service"
          label="Credit Services"
          textSize="body-s"
          onClick={() => handleOnRedirect('Credit Service', '/c/credit-center')}
          icon={CardCvv}
          iconSize="small"
          flexDirection="row"
          aria-label="Click to redirect to credit service page"
        />
      </div>
    </div>
  );
};

HeaderQuickAccess.displayName = 'HeaderB2BQuickAccess';

export { HeaderQuickAccess };
