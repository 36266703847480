export const getUrlSearchTerm = () => {
  const search = {
    findBy: {
      searchPath: () => {
        const url = window.location.href;
        const searchRegEx = /\/s\/(.*?)(\?|$|\/)/i;
        const isSearchPath = new RegExp(searchRegEx).test(url);
        if (isSearchPath) return (url.match(searchRegEx)[1]);
        return '';
      },
      params: () => {
        const params = new URLSearchParams(window.location.search);
        if (params.has('keyword')) return params.get('keyword');
        if (params.has('searchRedirect')) return params.get('searchRedirect');
        return null;
      }
    }
  };
  const searchTerm = search.findBy.params() || search.findBy.searchPath();
  return (decodeURIComponent(searchTerm) || searchTerm).replace(/\+/g, ' ');
};
