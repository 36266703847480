import { createContext, useContext } from 'react';

export const defaultContextValues = () => {
  return {
    customerType: 'default',
    contactCenter: {
      number: '',
      smsNumber: ''
    }
  };
};

export const FooterContext = createContext(defaultContextValues());

export const useFooterContext = () => {
  const context = useContext(FooterContext);
  if (context === undefined) {
    throw new Error('useFooterContext must be used within a FooterContextProvider');
  }
  return context;
};