import React, { useEffect, useState } from 'react';
import { node, oneOf } from 'prop-types';
import { FooterContext, defaultContextValues } from './FooterContext';
import { headerEvent } from '../../events/header.events';
import { CONSTANTS } from '../../constants';

const FooterContextProvider = ({
  children = null,
  customerType = 'default'
}) => {
  const [contactCenter, setContactCenter] = useState(null);

  useEffect(() => {
    const contactCenterHandler = (event) => {
      const contactCenterData = event?.output;
      setContactCenter(contactCenterData);
    };

    headerEvent.listen({
      eventName: CONSTANTS.HEADER_CONTACT_CENTER_EVENT,
      onEvent: contactCenterHandler,
      uselifecycle: true
    });

    return () => {
      headerEvent.off({
        eventName: CONSTANTS.HEADER_CONTACT_CENTER_EVENT,
        onEvent: contactCenterHandler,
        uselifecycle: true
      });
    };
  }, []);

  const userContext = {
    ...defaultContextValues(),
    contactCenter,
    customerType
  };

  return (
    <FooterContext.Provider value={userContext}>
      {children}
    </FooterContext.Provider>
  );
};

FooterContextProvider.propTypes = {
  children: node,
  customerType: oneOf(['default', 'b2b'])
};

FooterContextProvider.displayName = 'FooterContextProvider';

export { FooterContextProvider };
