import classNames from 'classnames';
import { Scroll } from '../hooks/useScrollDirection';

/**
 * layout positions supported by header
 */
export const positioning = {
  stickyOnUpScroll: ({
    scrollYThreshold,
    scrollYPixels,
    direction
  }) => {
    const thresholdReached = scrollYPixels > scrollYThreshold && direction === Scroll.DOWN;

    /**
     * header-out-of-view
     * - is an arbitrary variant for nested elements with a fixed or absolute position.
     * - this will allow different behaviors when root of header slides out of view.
    */
    const rootClasses = classNames('sui-sticky sui-z-header sui-transition-[top] sui-duration-500',
      {
        'sui-top-0': !thresholdReached,
        'header-out-of-view -sui-top-36': thresholdReached
      }
    );
    return { root: { classes: rootClasses } };
  },

  sticky: () => {
    const rootClasses = 'sui-sticky sui-z-header sui-top-0';

    return { root: { classes: rootClasses } };
  }
};
