import { useEffect, useState, useRef } from 'react';
import { throttle } from '@thd-olt-functional/utils';

export const Scroll = Object.freeze({
  TIMEOUT_ID: '__HEADER_USE_SCROLL_DIRECTION_TIMER',
  TIME_TO_RESET_MS: 150,
  THROTTLE_MS: 50,
  UP: 'up',
  DOWN: 'down',
  SCROLL_THRESHOLD_PX: 2
});

const useScrollDirection = (
  timeToResetMs = Scroll.TIME_TO_RESET_MS,
  throttleMs = Scroll.THROTTLE_MS
) => {
  const scrollRef = useRef({ prevScrollDirection: null, scrollYPixels: null });

  const [scrollDirection, setScrollDirection] = useState(null);

  const handleResetTimeout = () => {
    clearTimeout(window[Scroll.TIMEOUT_ID]);

    const onReset = () => {
      setScrollDirection(null);
    };

    window[Scroll.TIMEOUT_ID] = setTimeout(onReset, timeToResetMs);
  };

  const onScroll = throttle(throttleMs, () => {
    // set timeout to continue receiving up/down scroll events
    handleResetTimeout();

    const scrollYPixels = Math.round(window.pageYOffset);

    if (scrollRef.current.scrollYPixels === null) {
      scrollRef.current.scrollYPixels = scrollYPixels;
      return;
    }

    const newDirection = scrollYPixels > scrollRef.current.scrollYPixels ? Scroll.DOWN : Scroll.UP;

    // Amount of pixels scrolled since threshold was met
    const scrolledPixels = (Math.abs(scrollRef.current.scrollYPixels - scrollYPixels));

    const scrollThresholdMet = scrolledPixels >= Scroll.SCROLL_THRESHOLD_PX;

    scrollRef.current.scrollYPixels = scrollYPixels;

    // patch to prevent change in scroll state when scroll is locked
    if (document.body.style.overflow === 'hidden') return;

    if (scrollThresholdMet) {
      setScrollDirection(newDirection);
    }
  });

  useEffect(() => {
    // initialize reset timeout
    window[Scroll.TIMEOUT_ID] = null;

    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    // keep track of previous direction for when timeout sets direction state to null
    scrollRef.current.prevScrollDirection = scrollDirection;
  }, [scrollDirection]);

  return {
    scrollDirection,
    prevScrollDirection: scrollRef.current.prevScrollDirection,
    scrollYPixels: scrollRef.current.scrollYPixels
  };
};

export default useScrollDirection;