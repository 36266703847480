import { HOST_CONSTANTS } from '../../constants';
import { headerEvent } from '../../events/header.events';

const { DEFAULT_HOST } = HOST_CONSTANTS;

const handleNavLinksClickAnalytics = (event) => {
  headerEvent.publish({
    eventName: 'footer-nav-links.nav-links-click',
    payload: { clickPath: event?.target?.title.toLowerCase() + ' footer link' }
  });
};

const handleLegalLinksClickAnalytics = (event) => {
  headerEvent.publish({
    eventName: 'footer-legal-links.legal-links-click',
    payload: { clickPath: event?.target?.title.toLowerCase() + ' subfooter link' }
  });
};

export const BRANDS = [
  {
    href: 'https://www.homedepot.com/b/The-Company-Store/N-5yc1vZms7',
    name: 'The Company Store',
    src: 'https://assets.thdstatic.com/images/v1/brand-logos/TheCompanyStore_logo.svg'
  },
  {
    href: 'https://www.homedepot.com/b/Vissani/N-5yc1vZ4rj',
    name: 'Vissani',
    src: 'https://assets.thdstatic.com/images/v1/brand-logos/vissani_logo.svg'
  },
  {
    href: 'https://www.homedepot.com/b/Home-Decorators-Collection/N-5yc1vZ4vr',
    name: 'Home Decorators Collection',
    src: 'https://assets.thdstatic.com/images/v1/brand-logos/HomeDecoratorsCollection_logo.svg'
  },
  {
    href: 'https://www.homedepot.com/b/Hampton-Bay/N-5yc1vZp4',
    name: 'Hampton Bay',
    src: 'https://assets.thdstatic.com/images/v1/brand-logos/HamptonBay-footer-logo.svg'
  },
  {
    href: 'https://www.homedepot.com/b/Husky/N-5yc1vZrd',
    name: 'Husky',
    src: 'https://assets.thdstatic.com/images/v1/brand-logos/Husky-footer-logo.svg'
  },
  {
    href: 'https://www.homedepot.com/b/Flooring/Lifeproof/N-5yc1vZaq7rZnv7',
    name: 'Lifeproof Flooring',
    src: 'https://assets.thdstatic.com/images/v1/brand-logos/LifeProof-footer-logo.svg'
  },
  {
    href: 'https://www.homedepot.com/b/Lifeproof-with-Petproof-Technology/N-5yc1vZs7d',
    name: 'Lifeproof with Petproof Technology Carpet',
    src: 'https://assets.thdstatic.com/images/v1/brand-logos/lifeproof_v1.svg'
  },
  {
    href: 'https://www.homedepot.com/b/Glacier-Bay/N-5yc1vZn7',
    name: 'Glacier Bay',
    src: 'https://assets.thdstatic.com/images/v1/brand-logos/GlacierBay-footer-logo.svg'
  },
  {
    href: 'https://www.homedepot.com/b/Everbilt/N-5yc1vZ402',
    name: 'Everbilt',
    src: 'https://assets.thdstatic.com/images/v1/brand-logos/Everbilt-footer-logo.svg'
  },
  {
    href: 'https://www.homedepot.com/b/Home-Decor/StyleWell/N-5yc1vZas6pZn4x',
    name: 'StyleWell',
    src: 'https://assets.thdstatic.com/images/v1/brand-logos/StyleWell-logo.svg'
  },
  {
    href: 'https://www.homedepot.com/b/HDX/N-5yc1vZ9tk',
    name: 'HDX',
    src: 'https://assets.thdstatic.com/images/v1/brand-logos/Hdx-footer-logo.svg'
  },
  {
    href: 'https://www.homedepot.com/b/Vigoro/N-5yc1vZ1kt',
    name: 'Vigoro',
    src: 'https://assets.thdstatic.com/images/v1/brand-logos/vigoro.svg'
  },
  {
    href: 'https://www.homedepot.com/b/Flooring/TrafficMaster/N-5yc1vZaq7rZ5bx',
    name: 'TrafficMaster',
    src: 'https://assets.thdstatic.com/images/v1/brand-logos/TrafficMaster-footer-logo.svg'
  },
  {
    href: 'https://www.homedepot.com/b/Defiant/N-5yc1vZfw',
    name: 'Defiant',
    src: 'https://assets.thdstatic.com/images/v1/brand-logos/Defiant-footer-logo.svg'
  },
  {
    href: 'https://www.homedepot.com/b/Lighting/EcoSmart/N-5yc1vZbvn5Z4b8',
    name: 'Ecosmart',
    src: 'https://assets.thdstatic.com/images/v1/brand-logos/EcoSmart-footer-logo.svg'
  },
  {
    href: 'https://www.homedepot.com/b/Holiday-Decorations/Home-Accents-Holiday/N-5yc1vZbd6eZ3b3',
    name: 'Home Accents Holiday',
    src: 'https://assets.thdstatic.com/images/v1/brand-logos/HomeAccentsHoliday-footer-logo.svg'
  },
  {
    href: 'https://www.homedepot.com/b/Commercial-Electric/N-5yc1vZdn',
    name: 'Commercial Electric',
    src: 'https://assets.thdstatic.com/images/v1/brand-logos/Commercial-Electric.svg'
  },
  {
    href: 'https://www.homedepot.com/b/Smart-Home/Hubspace/N-5yc1vZc1jwZ1z1pr0w',
    name: 'Hubspace Smart Home',
    src: 'https://assets.thdstatic.com/images/v1/brand-logos/hubspace-logo.svg'
  }
];

export const NAV_LINKS_DEFAULT = [
  {
    name: 'Support',
    links: [
      {
        label: 'Customer Service Center',
        href: `${DEFAULT_HOST}/c/customer_service`
      },
      {
        label: 'Check Order Status',
        href: `${DEFAULT_HOST}/myaccount/guest-order-tracking`
      },
      {
        label: 'Pay Your Credit Card',
        href: `${DEFAULT_HOST}/c/credit-center`
      },
      {
        label: 'Order Cancellation',
        href: `${DEFAULT_HOST}/c/Customer_Support_Answers#cancel`
      },
      {
        label: 'Return Policy',
        href: `${DEFAULT_HOST}/c/Return_Policy`
      },
      {
        label: 'Refund Policy',
        href: `${DEFAULT_HOST}/c/refund-policy`
      },
      {
        label: 'Shipping & Delivery',
        href: `${DEFAULT_HOST}/c/About_Your_Online_Order`
      },
      {
        label: 'Product Recalls',
        href: `${DEFAULT_HOST}/c/Product_Recalls`
      },
      {
        label: 'My Preference Center',
        href: `${DEFAULT_HOST}/privacy/MyPreferenceCenter`,
        testid: 'my-preference-center-nav-links',
        onClick: handleNavLinksClickAnalytics
      },
      {
        label: 'Privacy & Security Center',
        href: `${DEFAULT_HOST}/privacy/overview`
      }
    ]
  },
  {
    name: 'Resources',
    links: [
      {
        label: 'Specials & Offers',
        href: `${DEFAULT_HOST}/c/Savings_Center`
      },
      {
        label: 'Military Discount Benefit',
        href: `${DEFAULT_HOST}/c/military`
      },
      {
        label: 'DIY Projects & Ideas',
        href: `${DEFAULT_HOST}/c/diy_projects_and_ideas`
      },
      {
        label: 'Truck & Tool Rental',
        href: `${DEFAULT_HOST}/c/tool_and_truck_rental`
      },
      {
        label: 'Installation & Services',
        href: `${DEFAULT_HOST}/services`
      },
      {
        label: 'Moving Supplies & Rentals',
        href: `${DEFAULT_HOST}/b/Storage-Organization-Moving-Supplies/N-5yc1vZc89r`
      },
      {
        label: 'Protection Plans',
        href: `${DEFAULT_HOST}/c/The_Home_Depot_Protection_Plans`
      },
      {
        label: 'Rebate Center',
        href: `${DEFAULT_HOST}/rebate-center/index.html`
      },
      {
        label: 'Gift Cards',
        href: `${DEFAULT_HOST}/c/gift-cards`
      },
      {
        label: 'Catalog',
        href: `${DEFAULT_HOST}/catalog`
      },
      {
        label: 'Subscriptions',
        href: `${DEFAULT_HOST}/c/subscription_service`
      }
    ]
  },
  {
    name: 'About Us',
    links: [
      {
        label: 'Careers',
        href: 'https://careers.homedepot.com/'
      },
      {
        label: 'Corporate Information',
        href: 'https://corporate.homedepot.com/'
      },
      {
        label: 'Digital Newsroom',
        href: 'https://corporate.homedepot.com/newsroom'
      },
      {
        label: 'Home Depot Foundation',
        href: 'https://corporate.homedepot.com/foundation'
      },
      {
        label: 'Investor Relations',
        href: 'https://ir.homedepot.com/'
      },
      {
        label: 'Government Customers',
        href: `${DEFAULT_HOST}/c/Government_Customers`
      },
      {
        label: 'Suppliers & Providers',
        href: `${DEFAULT_HOST}/c/suppliers_and_providers`
      },
      {
        label: 'Affiliate Program',
        href: 'https://www.homedepot.com/c/SF_MS_The_Home_Depot_Affiliate_Program'
      },
      {
        label: 'Eco Actions',
        href: 'https://ecoactions.homedepot.com/'
      },
      {
        label: 'Corporate Responsibility',
        href: 'https://corporate.homedepot.com/responsibility'
      },
      {
        label: 'Home Depot Licensing Information',
        href: 'https://www.homedepot.com/c/SV_HS_Contractor_License_Numbers?cm_sp=vanity-_-licensenumbers-_-MAY16'
      }
    ]
  }
];

export const NAV_LINKS_B2B = [
  {
    name: 'Support',
    links: [
      {
        label: 'Customer Service Center',
        href: `${DEFAULT_HOST}/c/pro_customer_support`
      },
      {
        label: 'Check Order Status',
        href: `${DEFAULT_HOST}/myaccount/purchase-history`
      },
      {
        label: 'Order Cancellation',
        href: `${DEFAULT_HOST}/c/Customer_Support_Answers#cancel`
      },
      {
        label: 'Return Policy',
        href: `${DEFAULT_HOST}/c/Return_Policy`
      },
      {
        label: 'Refund Policy',
        href: `${DEFAULT_HOST}/c/refund-policy`
      },
      {
        label: 'Shipping & Delivery',
        href: `${DEFAULT_HOST}/c/About_Your_Online_Order`
      },
      {
        label: 'Product Recalls',
        href: `${DEFAULT_HOST}/c/Product_Recalls`
      },
      {
        label: 'My Preference Center',
        href: `${DEFAULT_HOST}/privacy/MyPreferenceCenter`,
        testid: 'my-preference-center-nav-links',
        onClick: handleNavLinksClickAnalytics
      },
      {
        label: 'Privacy & Security Center',
        href: `${DEFAULT_HOST}/privacy/overview`
      }
    ]
  },
  {
    name: 'Resources',
    links: [
      {
        label: 'Specials & Offers',
        href: `${DEFAULT_HOST}/c/Savings_Center`
      },
      {
        label: 'Military Discount Benefit',
        href: `${DEFAULT_HOST}/c/military`
      },
      {
        label: 'Truck & Tool Rental',
        href: `${DEFAULT_HOST}/c/tool_and_truck_rental`
      },
      {
        label: 'Protection Plans',
        href: `${DEFAULT_HOST}/c/The_Home_Depot_Protection_Plans`
      },
      {
        label: 'Manufacturer and Municipality Rebates',
        href: `${DEFAULT_HOST}/rebate-center/index.html`
      },
      {
        label: 'Gift Cards',
        href: `${DEFAULT_HOST}/c/gift-cards`
      }
    ]
  },
  {
    name: 'About Us',
    links: [
      {
        label: 'Careers',
        href: 'https://careers.homedepot.com/'
      },
      {
        label: 'Corporate Information',
        href: 'https://corporate.homedepot.com/'
      },
      {
        label: 'Digital Newsroom',
        href: 'https://corporate.homedepot.com/newsroom'
      },
      {
        label: 'Home Depot Foundation',
        href: 'https://corporate.homedepot.com/foundation'
      },
      {
        label: 'Investor Relations',
        href: 'https://ir.homedepot.com/'
      },
      {
        label: 'Government Customers',
        href: `${DEFAULT_HOST}/c/Government_Customers`
      },
      {
        label: 'Suppliers & Providers',
        href: `${DEFAULT_HOST}/c/suppliers_and_providers`
      },
      {
        label: 'Eco Actions',
        href: 'https://ecoactions.homedepot.com/'
      }
    ]
  }
];

export const LEGAL_LINKS = [
  {
    label: 'Store Locator',
    href: `${DEFAULT_HOST}/l/store-locator`
  },
  {
    label: `©2000-${new Date().getFullYear()} Home Depot`,
  },
  {
    label: 'Privacy & Security Statement',
    href: `${DEFAULT_HOST}/privacy/privacy-and-security-statement`
  },
  {
    label: 'Terms',
    href: `${DEFAULT_HOST}/c/Terms_of_Use`
  },
  {
    label: 'My Preference Center',
    href: `${DEFAULT_HOST}/privacy/MyPreferenceCenter`,
    testid: 'my-preference-center-legal-links',
    onClick: handleLegalLinksClickAnalytics
  },
  {
    label: 'California Privacy Rights & Report',
    href: `${DEFAULT_HOST}/privacy/California-Privacy-Rights-and-Report`
  },
  {
    label: 'Limit the Use of My Sensitive Personal Information',
    href: `${DEFAULT_HOST}/privacy/Exercise-My-Privacy-Rights`
  },
  {
    label: 'Do Not Sell or Share My Personal Information',
    href: `${DEFAULT_HOST}/privacy/Exercise-My-Privacy-Rights`
  },
  {
    label: 'California Supply Chain Act',
    href: `${DEFAULT_HOST}/c/California_Supply_Chain_Act`
  },
];

export const EXCHANGE_LEGAL_LINKS = [
  {
    label: `©2000-${new Date().getFullYear()} Home Depot`,
  },
  {
    label: 'Privacy & Security Statement',
    href: `${DEFAULT_HOST}/privacy/privacy-and-security-statement`
  },
  {
    label: 'Terms',
    href: `${DEFAULT_HOST}/c/Terms_of_Use`
  },
  {
    label: 'California Privacy Rights & Report',
    href: `${DEFAULT_HOST}/privacy/California-Privacy-Rights-and-Report`
  },
  {
    label: 'California Supply Chain Act',
    href: `${DEFAULT_HOST}/c/California_Supply_Chain_Act`
  },
];

export const DESKTOP_LEGAL_LINKS = [
  {
    label: 'Site Map',
    href: `${DEFAULT_HOST}/c/site_map`
  },
  {
    label: 'Store Directory',
    href: `${DEFAULT_HOST}/l/storeDirectory`
  },
];

// TODO: move social icons from legacy HFS (./assets/svg) to static assets bucket and load from there
export const SOCIAL_BUTTONS = [
  {
    href: 'https://www.facebook.com/homedepot',
    target: '_blank',
    label: 'Home Depot on Facebook',
    src: 'https://assets.thdstatic.com/images/v1/path-to-pro/FacebookThd.svg'
  },
  {
    href: 'https://www.twitter.com/homedepot',
    target: '_blank',
    label: 'Home Depot on Twitter',
    src: 'https://assets.thdstatic.com/images/v1/path-to-pro/Twitter.svg'
  },
  {
    href: 'https://www.pinterest.com/homedepot/',
    target: '_blank',
    label: 'Home Depot on Pinterest',
    src: 'https://assets.thdstatic.com/images/v1/path-to-pro/Pinterest.svg'
  },
  {
    href: 'https://blog.homedepot.com/',
    target: '_blank',
    label: 'Home Depot Blog',
    src: 'https://assets.thdstatic.com/images/v1/path-to-pro/Blog.svg'
  },
  {
    href: 'https://www.youtube.com/user/homedepot',
    target: '_blank',
    label: 'Home Depot on YouTube',
    src: 'https://assets.thdstatic.com/images/v1/path-to-pro/YoutubeThd.svg'
  },
  {
    href: `${DEFAULT_HOST}/c/SF_Mobile_Shopping`,
    target: '_self',
    label: 'Home Depot Mobile Apps',
    src: 'https://assets.thdstatic.com/images/v1/path-to-pro/Mobile.svg'
  }
];

export const OTHER_SITE_LINKS = [
  {
    label: 'The Home Depot Canada',
    href: 'https://www.homedepot.ca/'
  },
  {
    label: 'The Home Depot México',
    href: 'http://www.homedepot.com.mx/'
  },
  {
    label: 'Pro Referral',
    href: 'https://www.proreferral.com/'
  }
];
