/* eslint-disable tailwindcss/no-arbitrary-value */
import React, { useState, useEffect, Suspense } from 'react';
import { node } from 'prop-types';
import classNames from 'classnames';
import { positioning } from '../../utils/headerLayoutOpts';
import useScrollDirection from '../../hooks/useScrollDirection';
import useClientRect from '../../hooks/useClientRect';
import { useThemeContext } from '../generic-ui/ThemeContext';
import { headerEvent } from '../../events/header.events';
import { useDesktopBreakpoint } from '../../utils/headerUtils';
import { useHeaderContext } from '../generic-ui/HeaderContext';

const HeaderDrawer = React.lazy(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "HeaderDrawer" */
  '../drawer-ui/HeaderDrawer')
  // eslint-disable-next-line no-console
  .catch((error) => console.warn(`Error occurred while loading header drawer- ${error}`)));
const MAHeaderWrapper = React.lazy(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "MAHeaderWrapper" */
  '../magic-apron/MAHeaderWrapper')
  // eslint-disable-next-line no-console
  .catch((error) => console.warn(`Error occurred while loading magic apron assistant- ${error}`)));

const PositionedHeader = ({
  children = null, topHatComponent = null
}) => {
  const {
    scrollDirection,
    prevScrollDirection,
    scrollYPixels
  } = useScrollDirection(20, 20);

  const [rootRect, setRootRectRef] = useClientRect();
  const [topHatRect, setTopHatRectRef] = useClientRect();

  const { inverseTheme } = useThemeContext();

  const { featureSwitches } = useHeaderContext();

  /**
   * use prev. direction when useScrollDirection resets direction to null on timeout
   */
  const direction = scrollDirection || prevScrollDirection;
  const scrollYThreshold = +rootRect?.height + +topHatRect?.height;
  const isDesktop = useDesktopBreakpoint();

  const [positionLayout, setPositionLayout] = useState({ position: 'sticky-on-scroll', hideBottomNav: false });

  useEffect(() => {
    headerEvent.listen({
      eventName: 'header.header-positioning-layout',
      onEvent: ({ output = {} }) => {
        const { position = 'sticky-on-scroll', hideBottomNav = false } = output;
        setPositionLayout({ position, hideBottomNav });
      }
    });
  }, []);

  const getPositionLayout = () => {
    if (positionLayout?.position === 'sticky') {
      return positioning.sticky();
    }
    return positioning.stickyOnUpScroll({
      scrollYThreshold,
      scrollYPixels,
      direction,
    });
  };

  const headerRootClasses = classNames(
    'print:sui-contents',
    'sui-border-b-1 sui-border-primary sui-border-solid', {
      'sui-bg-pro': inverseTheme,
      'sui-bg-primary': !inverseTheme,
      'header-bottom-nav-hidden': !isDesktop && positionLayout?.hideBottomNav
    });

  return (
    <>
      <div ref={setTopHatRectRef}>
        {topHatComponent}
      </div>
      <div
        id="header-root"
        data-testid="header-root"
        className={classNames(getPositionLayout()?.root?.classes, headerRootClasses)}
        data-component="Header"
      >
        <div id="header-content" className="sui-max-w-header-screen-3xl sui-m-auto" ref={setRootRectRef}>
          {children}
          {rootRect && (
            <Suspense fallback={null}>
              <HeaderDrawer />
              {featureSwitches.isMagicApronAssistantEnabled && (<MAHeaderWrapper />)}
            </Suspense>
          )}
        </div>
      </div>

    </>
  );
};

PositionedHeader.displayName = 'PositionedHeader';

PositionedHeader.propTypes = {
  children: node,
  topHatComponent: node
};

export default PositionedHeader;
