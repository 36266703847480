import THDStorageUtils, { COOKIE_STORAGE } from '@thd-olt-global/thd-storage-utils';
/* eslint-disable id-length */
import { Search } from '@one-thd/sui-icons';
import { HOST_CONSTANTS, CONSTANTS } from '../../../../constants';

export const typeaheadAPI = {
  url: ({ requestSearchTerm, prevQuery }) => {
    // the string replace here protects against malformedURI errors
    const searchTermCleaned = encodeURIComponent(requestSearchTerm.replace(/\\u/i, ' '));
    const prevQueryParam = prevQuery ? `&prevQuery=${prevQuery}` : '';
    return `/TA2/search?term=${searchTermCleaned}${prevQueryParam}`;
  },

  fetch: async ({ searchText, config, recentSearches }) => {
    const prevQuery = recentSearches?.[0]?.keyword || null;
    const searchTermUrl = typeaheadAPI.url({
      requestSearchTerm: searchText, prevQuery
    });
    const urlWithHostName = HOST_CONSTANTS.DEFAULT_HOST + searchTermUrl;

    let fetchConfig = {};
    if (config?.isSearchTermBoosted) {
      const recentlySearched = recentSearches?.map((val) => val.keyword).join('|');
      fetchConfig.method = 'post';
      fetchConfig.body = JSON.stringify({ recentlySearched });
    }
    fetchConfig.headers = { 'xtt-searchbar': config?.typeaheadApiAbTest };

    try {
      const response = await fetch(urlWithHostName, fetchConfig);
      const data = await response.json();

      const { r: results, cta } = data;
      return { results, cta };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Search term API Error:', error);
      return null;
    }
  },

  format: (typeAheadData) => {
    return typeAheadData?.filter((resultsObj) => typeof resultsObj?.t === 'string')
      .map(({ k: taLink, t: title, v: rawCategories }) => {

        const link = `/s/${encodeURIComponent(title)}?NCNI-5`;

        const categories = (rawCategories?.length > 0)
          ? rawCategories.map(({ k: cLink, t: cTitle }) => ({ cLink, cTitle }))
          : null;

        return ({
          link,
          title,
          categories,
          icon: Search,
          source: CONSTANTS.TYPEAHEAD_DATA_SOURCE.TA_V2_SEARCH_API,
          removable: false
        });
      });
  }
};

export const typeaheadApiCookieStore = {
  getBoosting: () => {
    try {
      return THDStorageUtils.get(
        COOKIE_STORAGE,
        { key: 'x-ttsearch' })?.split(',').filter((term) => term.trim().toLowerCase() === 'ta_boosting').length > 0;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error({ message: 'Error occurred while fetching cookie - x-ttsearch', error });
      return null;
    }
  },
  getABTest: () => {
    try {
      return THDStorageUtils.get(COOKIE_STORAGE, { key: 'xtt-searchbar' });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error({ message: 'Error occurred while fetching cookie - xtt-searchbar', error });
      return null;
    }
  }

};
