import { useContext, createContext } from 'react';

export const defaultHeaderContextValues = () => {
  return {
    featureSwitches: {
      isRecentlyViewedEnabled: false
    },
  };
};
export const HeaderContext = createContext(defaultHeaderContextValues());

export const useHeaderContext = () => {
  const context = useContext(HeaderContext);

  if (context === undefined) {
    throw new Error('useHeaderContext must be used within a HeaderProvider');
  }
  return context;
};