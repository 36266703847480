import Localizer from 'Localizer';
import { gpsPermissions, CONSTANTS } from '../constants';
import { headerEvent } from './header.events';
import searchHistoryStore from '../components/header-ui/search/utils/searchHistory';

const sendDigitalDataEvent = (eventData) => {
  if (typeof window === 'undefined' || !window?.digitalData) return;

  window.digitalData.event = window.digitalData.event || [];
  window.digitalData.event.push(eventData);
};

const sendPageDataEvent = (eventData) => {
  if (typeof window === 'undefined' || !window?._T) return;

  window._T.pageData = window._T.pageData || [];
  window._T.pageData.push(eventData);
};

const captureClickAnalytics = (clickPath) => {
  const thdAnalyticsEvent = {
    eventInfo: {
      eventName: 'header click'
    },
    category: {
      primaryCategory: 'stash'
    },
    eventMode: 'stash',
    stash: {
      clickInfo: 'header>' + clickPath
    }
  };
  sendDigitalDataEvent(thdAnalyticsEvent);
};

const captureSubmitAnalytics = (name) => {
  const thdAnalyticsEvent = {
    eventInfo: {
      eventName: name
    },
    category: {
      primaryCategory: 'button submission'
    }
  };
  sendDigitalDataEvent(thdAnalyticsEvent);
};

const captureOverlayAnalytics = (path) => {
  const thdAnalyticsEvent = {
    eventInfo: {
      eventName: path
    },
    category: {
      primaryCategory: 'overlay'
    }
  };
  sendDigitalDataEvent(thdAnalyticsEvent);
};

const captureLocalizationAnalytics = (storeId) => {
  const gpsStatus = Localizer?.status?.getGpsStatus()?.toLowerCase();

  const event = {
    user: {
      localization: {
        method: 'ip-db:self-localized',
        storeNumber: storeId,
        gpsPermission: gpsPermissions[gpsStatus] || 'timeout'
      }
    }
  };
  sendPageDataEvent(event);
};

const captureZipUpdateAnalytics = (newZip) => {
  const thdAnalyticsEvent = {
    user: {
      localization: {
        userZip: newZip
      }
    }
  };

  sendDigitalDataEvent(thdAnalyticsEvent);
};

const handleSearchSuggestionClickAnalytics = (
  selectedSearchTerm, results, userSearchTerm, type, cta, eventName = 'type ahead') => {
  const { TYPEAHEAD_DATA_SOURCE: { SEARCH_HISTORY } } = CONSTANTS;

  const formattedResults = results?.reduce((acc, result) => {
    const { title, categories } = result;
    return { ...acc, [title]: categories?.map((category) => category?.cTitle) };
  }, {});

  let searchType = type || 'suggested term';
  const searchMedium = (type !== null && type === SEARCH_HISTORY) ? 'type ahead>recent search' : 'type ahead';
  const mainSearchResults = Object.keys(formattedResults);
  const categorySearchResults = Object.values(formattedResults).flat().filter((value) => value);

  const searchTypeAheadBit = `
  ${+!!cta}|${(() => {
    if (searchType === 'suggested term' && mainSearchResults.includes(selectedSearchTerm)) {
      return '1';
    }

    if (searchType === 'suggested term' && categorySearchResults.includes(selectedSearchTerm)) {
      searchType += ' in category';
      return '2';
    }

    return '4';
  })()}|${
    mainSearchResults.includes(selectedSearchTerm)
      ? mainSearchResults.indexOf(selectedSearchTerm) + 1
      : categorySearchResults.indexOf(selectedSearchTerm) + 1
  }`.trim();

  const typeaheadAnalyticsEvent = {
    eventInfo: {
      eventName
    },
    category: {
      primaryCategory: 'stash'
    },
    eventMode: 'stash',
    stash: {
      searchMedium,
      searchTerm: selectedSearchTerm,
      searchTermOrigin: userSearchTerm,
      searchTypeAheadType: searchType,
      searchTypeAheadBits: searchTypeAheadBit,
      searchTypeAheadImp: mainSearchResults
    }
  };

  sendDigitalDataEvent(typeaheadAnalyticsEvent);
};

const handleRecentlyViewedClickAnalytics = (displayPosition, searchTerm) => {
  const pageType = window.digitalData?.page?.pageInfo?.pageType || 'recently viewed pip load';
  const searchHistoryResults = searchHistoryStore.get();
  const recentSearches = searchHistoryStore?.format(searchHistoryResults)?.map(({ title }) => title) || [];
  const typeaheadAnalyticsEvent = {
    category: {
      primaryCategory: 'stash'
    },
    eventInfo: {
      eventName: 'recently viewed pip load',
    },
    eventMode: 'stash',
    stash: {
      recommendation: {
        recommendationType: 'rv_typeahead_rr',
        pageType,
        displayPosition,
        anchorProductSku: 'n/a',
        strategy: 'n/a',
        recommendationVersion: 'v1',
        featureVersion: '1',
        interval: 'n/a',
      }
    }
  };
  const typeaheadAnalyticsEventImpression = {
    category: {
      primaryCategory: 'stash'
    },
    eventInfo: {
      eventName: 'type ahead',
    },
    eventMode: 'stash',
    stash: {
      searchMedium: 'type ahead>recently viewed',
      searchTerm,
      searchTermOrigin: '',
      searchTypeAheadBits: `0|3|${displayPosition}`,
      searchTypeAheadImp: recentSearches,
      searchTypeAheadType: 'recently viewed',
    }
  };
  sendDigitalDataEvent(typeaheadAnalyticsEvent);
  sendDigitalDataEvent(typeaheadAnalyticsEventImpression);
};

const captureMyPreferencesCenterClickAnalytics = (clickPath) => {
  const thdAnalyticsEvent = {
    eventInfo: {
      eventName: 'my account settings'
    },
    category: {
      primaryCategory: 'stash'
    },
    eventMode: 'stash',
    stash: {
      myAccount: {
        settings: clickPath
      }
    }
  };
  sendDigitalDataEvent(thdAnalyticsEvent);
};

/**
 * header listener events
 * new listener should follow this naming convention:
 * header-{header-component-name}.{event-name}
 */
export const headerAnalytics = {
  listen: () => {
    if (!window?.LIFE_CYCLE_EVENT_BUS) {
      // eslint-disable-next-line no-console
      console.error('life cycle event bus was not found, unable to setup listeners for header analytics');
      return;
    }

    headerEvent.listen({
      eventName: 'header.delivery-zip-initialized',
      onEvent: ({ output = {} }) => {
        const { userZip } = output;

        if (typeof window === 'undefined' || !document) return;

        if (window?.digitalData?.user?.localization) {
          window.digitalData.user.localization.userZip = userZip;
        } else {
          document.addEventListener('globalDDOready', () => {
            window.digitalData.user.localization.userZip = userZip;
          });
        }
      }
    });

    headerEvent.listen({
      eventName: 'header.header-click',
      onEvent: ({ output = {} }) => {
        const { clickPath } = output;
        captureClickAnalytics(clickPath);
      }
    });

    headerEvent.listen({
      eventName: 'header-my-store.header-click',
      onEvent: ({ output = {} }) => {
        const { clickPath } = output;
        captureClickAnalytics(clickPath);
      }
    });

    headerEvent.listen({
      eventName: 'header.my-store-click',
      onEvent: ({ output = {} }) => {
        const { clickPath } = output;
        captureClickAnalytics(clickPath);
        captureOverlayAnalytics('store finder>stores list');
      }
    });

    headerEvent.listen({
      eventName: 'header-account.header-click',
      onEvent: ({ output = {} }) => {
        const { clickPath } = output;
        captureClickAnalytics(clickPath);
      }
    });

    headerEvent.listen({
      eventName: 'header-store-search.find-store-click',
      onEvent: ({ output = {} }) => captureSubmitAnalytics('find a store')
    });

    headerEvent.listen({
      eventName: 'header-store-pod.select-store-click',
      onEvent: ({ output = {} }) => {
        const { storeId } = output;
        captureSubmitAnalytics('make this my store');
        captureLocalizationAnalytics(storeId);
      }
    });

    headerEvent.listen({
      eventName: 'header-delivery-zip-code.zip-code-update-click',
      onEvent: ({ output = {} }) => {
        const { zipCode } = output;
        captureZipUpdateAnalytics(zipCode || 'na');
      }
    });

    headerEvent.listen({
      eventName: 'header-typeahead.result-click',
      onEvent: ({ output = {} }) => {
        const {
          selectedSearchTerm, results, userSearchTerm, type, cta
        } = output;
        handleSearchSuggestionClickAnalytics(selectedSearchTerm, results, userSearchTerm, type, cta);
      }
    });

    headerEvent.listen({
      eventName: 'header-typeahead.recently-viewed-click',
      onEvent: ({ output = {} }) => {
        const {
          displayPosition,
          searchTerm,
        } = output;
        handleRecentlyViewedClickAnalytics(
          displayPosition,
          searchTerm
        );
      }
    });
  }
};

/**
 * footer listener events
 * new listener should follow this naming convention:
 * footer-{footer-component-name}.{event-name}
 */
export const footerAnalytics = {
  listen: () => {
    if (!window?.LIFE_CYCLE_EVENT_BUS) {
      // eslint-disable-next-line no-console
      console.error('life cycle event bus was not found, unable to setup listeners for footer analytics');
      return;
    }

    headerEvent.listen({
      eventName: 'footer-nav-links.nav-links-click',
      onEvent: ({ output = {} }) => {
        const { clickPath } = output;
        captureMyPreferencesCenterClickAnalytics(clickPath);
      }
    });

    headerEvent.listen({
      eventName: 'footer-legal-links.legal-links-click',
      onEvent: ({ output = {} }) => {
        const { clickPath } = output;
        captureMyPreferencesCenterClickAnalytics(clickPath);
      }
    });
  }
};
