// A function for shimming scheduler.yield and setTimeout:
const yieldToMain = () => {
  // Use scheduler.yield if it exists:
  if ('scheduler' in window && 'yield' in window.scheduler) {
    return window.scheduler.yield();
  }

  // Fall back to setTimeout:
  return new Promise((resolve) => {
    setTimeout(resolve, 0);
  });
};

export default yieldToMain;
