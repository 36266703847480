import React, { createContext, useContext } from 'react';

export const defaultContent = (position) => {
  return {
    name: '',
    title: '',
    navigationTracker: [],
    drawerContent: null,
    position: (position || 'right'),
    open: false
  };
};

export const DrawerContext = createContext(defaultContent());

export const useDrawerContext = () => {
  return useContext(DrawerContext);
};
