import { useCallback, useState } from 'react';

const useClientRect = () => {
  const [rect, setRect] = useState(null);

  const setRectRef = useCallback((node) => {
    if (typeof node?.getBoundingClientRect !== 'function') return;

    setRect(node.getBoundingClientRect());
  }, []);

  return [rect, setRectRef];
};

export default useClientRect;