import { debounce } from '@thd-olt-functional/utils';
import { DEBOUNCE_DELAY_MS } from './constants';
import yieldToMain from './yieldToMain';

export const CUSTOM_SUBMIT_EVENTS = Object.freeze({
  ENTER_KEY: 'Enter',
  SEARCH_ICON_CLICK: 'onSearchIconClick',
  MENU_ITEM_CLICK: 'onMenuItemClick'
});

const originalEvent = {
  onSubmit: ({ event, customEvent = {}, onSubmitFn }) => {
    const {
      type, selectedSearchTerm, url, userSearchTerm, results, source
    } = customEvent;

    const validEvent = Object.values(CUSTOM_SUBMIT_EVENTS).includes(type);

    if (!validEvent || typeof onSubmitFn !== 'function') return;

    onSubmitFn(event, {
      type,
      selectedSearchTerm,
      url,
      userSearchTerm,
      results,
      source
    });
  },

  onChange: ({ event, onChangeFn }) => {
    if (!event || typeof onChangeFn !== 'function') return;
    onChangeFn(event, event?.target?.value);
  },

  onFocus: ({ event, onFocusFn }) => {
    if (!event || typeof onFocusFn !== 'function') return;
    onFocusFn(event);
  }
};

const debounceEvent = {
  onSubmit: debounce(DEBOUNCE_DELAY_MS.DEFAULT, originalEvent.onSubmit),

  onChange: debounce(DEBOUNCE_DELAY_MS.DEFAULT, originalEvent.onChange),
};

export const handleEvent = {
  onSubmit: async (event, customEvent, onSubmitFn, debounceEnabled = false) => {
    if (typeof event?.persist === 'function') event.persist();

    await yieldToMain();

    if (debounceEnabled) {
      return debounceEvent.onSubmit({ event, customEvent, onSubmitFn });
    }
    return originalEvent.onSubmit({ event, customEvent, onSubmitFn });
  },

  onChange: async (event, onChangeFn, debounceEnabled = false) => {
    if (typeof event?.persist === 'function') event.persist();

    await yieldToMain();

    if (debounceEnabled) {
      return debounceEvent.onChange({ event, onChangeFn });
    }
    return originalEvent.onChange({ event, onChangeFn });
  },

  onFocus: async (event, onFocusFn) => {
    if (typeof event?.persist === 'function') event.persist();

    await yieldToMain();

    return originalEvent.onFocus({ event, onFocusFn });
  }
};
