import React, { createContext, useContext } from 'react';

export const defaultThemeValues = () => {
  return { inverseTheme: false };
};

export const ThemeContext = createContext(defaultThemeValues());

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }
  return context;
};
