/* eslint-disable no-console */
import { render, createRoot } from 'react-dom';
import createEarnedMedia from './earned-media';

const VERSION = require('../../../package.json').version;

const styles = {
  B2C: `https://assets.thdstatic.com/hf/v${VERSION}/css/hf-scoped-styles.css`,
  B2B: `https://assets.thdstatic.com/hf-b2b/v${VERSION}/css/hf-scoped-styles.css`
};

const header = {
  create: {
    rootNode: () => {
      const node = document.createElement('div');
      node.style.display = 'contents';
      node.className = 'hfapp';
      return node;
    },
    subRootNode: ({ headerId }) => {
      const node = document.createElement('div');
      // eslint-disable-next-line no-nested-ternary
      node.id = headerId;
      node.style.display = 'contents';
      return node;
    }
  },
  inject: {
    styles: async ({ isB2B }) => {
      return new Promise((resolve, reject) => {
        const link = document.createElement('link');
        link.id = 'header-static-client-styles';
        link.setAttribute('rel', 'stylesheet');
        link.setAttribute('type', 'text/css');
        link.setAttribute('href', isB2B ? styles.B2B : styles.B2C);
        link.onload = () => resolve();
        link.onerror = () => { console.error('failed to load hf scoped styles'); resolve(); };
        document.head.appendChild(link);
      });
    }
  }
};

const hfClient = {
  header: {
    buildAndRender: async ({ reactNode, headerId }) => {
      const headerSubRoot = header.create.subRootNode({ headerId });
      const headerRoot = header.create.rootNode();
      headerRoot.append(createEarnedMedia());
      headerRoot.append(headerSubRoot);
      document.body.prepend(headerRoot);

      if (createRoot !== undefined) {
        const root = createRoot(headerSubRoot);
        root.render(reactNode);
      } else {
        render(reactNode, headerSubRoot);
      }
    }
  },
  footer: {
    buildAndRender: ({ reactNode, footerId }) => {
      const footerRoot = document.createElement('div');

      // eslint-disable-next-line no-nested-ternary
      footerRoot.id = footerId;
      footerRoot.className = 'hfapp';
      document.body.append(footerRoot);

      if (createRoot !== undefined) {
        const root = createRoot(footerRoot);
        root.render(reactNode);
      } else {
        render(reactNode, footerRoot);
      }
    }
  }
};

export const hfClientManager = {
  headerfooter: {
    buildAndRender: async ({
      headerNode, footerNode, isB2B, footerId, headerId
    }) => {
      await header.inject.styles({ isB2B });

      hfClient.header.buildAndRender({ reactNode: headerNode, headerId });
      hfClient.footer.buildAndRender({ reactNode: footerNode, footerId });
    }
  }
};
