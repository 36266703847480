import { CONSTANTS } from '../constants';

const captureHeaderVersion = (eventData) => {
  if (typeof window === 'undefined' || !window?.digitalData) return;
  window.digitalData.page = window.digitalData?.page || {};
  window.digitalData.page.pageInfo = window.digitalData?.page?.pageInfo || {};

  window.digitalData.page.pageInfo.headerVersion = `header > ${eventData}`;
};

export const headerVersion = {
  listen: () => {
    if (!document) {
      // eslint-disable-next-line no-console
      console.error('document was not found, unable to setup listeners for headerVersion analytics');
      return;
    }

    // TODO: Update to use LCEB once all pages have been migrated to Nucleus
    // Using document listener as a temporary solution
    document.addEventListener('globalDDOready', () => {
      const { HEADER_VERSION } = CONSTANTS;

      // TODO: Remove timeout after cart team refactors their pageInfo setter
      // Timeout was added to get around their page overwriting the pageInfo object on render
      // eslint-disable-next-line max-len
      // https://github.com/one-thd/ocm-cart-fed-mycart/blob/afe5bc453206ecea75440e84fbbf4f672b6b5e2b/src/main/resources/static/js/app/modules/dataCollector.js#L66
      setTimeout(() => {
        captureHeaderVersion(HEADER_VERSION);
      }, 100);
    });
  }
};