import { createContext, useContext } from 'react';

export const defaultContent = () => {
  return {
    list: [],
    active: null
  };
};

export const ComboboxContext = createContext(defaultContent());

export const useComboboxContext = () => {
  return useContext(ComboboxContext);
};
