/* eslint-disable */
const EARNED_MEDIA = [
  { label: 'Specials & Offers', URL: '/c/Savings_Center' },
  { label: 'Appliances', URL: '/b/Appliances/N-5yc1vZbv1w' },
  { label: 'Bath', URL: '/b/Bath/N-5yc1vZbzb3' },
  { label: 'Blinds & Window Treatments', URL: '/b/Window-Treatments/N-5yc1vZar4w' },
  { label: 'Building Materials', URL: '/b/Building-Materials/N-5yc1vZaqns' },
  { label: 'Lumber & Composites', URL: '/b/Lumber-Composites/N-5yc1vZbqpg' },
  { label: 'Cleaning', URL: '/b/Cleaning/N-5yc1vZbqsi' },
  { label: 'Home Decor', URL: '/b/Home-Decor/N-5yc1vZas6p' },
  { label: 'Lighting', URL: '/b/Lighting/N-5yc1vZbvn5' },
  { label: 'Doors & Windows', URL: '/b/Doors-Windows/N-5yc1vZaqih' },
  { label: 'Electrical', URL: '/b/Electrical/N-5yc1vZarcd' },
  { label: 'Flooring', URL: '/b/Flooring/N-5yc1vZaq7r' },
  { label: 'Hardware', URL: '/b/Hardware/N-5yc1vZc21m' },
  { label: 'Heating, Venting & Cooling', URL: '/b/Heating-Venting-Cooling/N-5yc1vZc4k8' },
  { label: 'Kitchen', URL: '/b/Kitchen/N-5yc1vZar4i' },
  { label: 'Garden Center', URL: '/b/Outdoors-Garden-Center/N-5yc1vZbx6k' },
  { label: 'Outdoors', URL: '/b/Outdoors/N-5yc1vZbx82' },
  { label: 'Paint', URL: '/b/Paint/N-5yc1vZar2d' },
  { label: 'Plumbing', URL: '/b/Plumbing/N-5yc1vZbqew' },
  { label: 'Holiday Decorations', URL: '/b/Holiday-Decorations/N-5yc1vZbd6e' },
  { label: 'Smart Home', URL: '/b/Smart-Home/N-5yc1vZc1jw' },
  { label: 'Storage & Organization', URL: '/b/Storage-Organization/N-5yc1vZas7e' },
  { label: 'Tools', URL: '/b/Tools/N-5yc1vZc1xy' },
  { label: 'Automotive', URL: '/b/Automotive/N-5yc1vZc8o1' },
  { label: 'Furniture', URL: '/b/Furniture/N-5yc1vZc7pc' },
  { label: 'Home Depot Gift Cards', URL: '/c/gift-cards' },
  { label: 'Credit Card Services', URL: '/c/credit-center' },
  { label: 'Home Services', URL: '/services/' },
  { label: 'Home Depot Rental', URL: '/c/tool_and_truck_rental' },
  { label: 'Pro Services & Contractor Supply', URL: '/c/pro' },
  { label: 'Project Calculators', URL: '/c/project_calculators' },
  { label: 'DIY Projects and Ideas', URL: '/c/diy_projects_and_ideas' },
  { label: 'Local Ad', URL: '/c/localad' },
  { label: 'Store Finder', URL: '/l/store-locator' },
  { label: 'Site Map', URL: '/c/site_map' }
];

const createEarnedMedia = () => {
  const earnedMediaEle = document.createElement('div');
  earnedMediaEle.id = 'header-earned-media-links';
  earnedMediaEle.style.display = 'none';

  EARNED_MEDIA.forEach(({ label, URL }) => {
    const emLink = document.createElement('a');
    emLink.href = URL;
    emLink.innerHTML = label;
    earnedMediaEle.append(emLink);
  });

  return earnedMediaEle;
};

export default createEarnedMedia;