import React, { useState, useEffect, useRef, Suspense } from 'react';
import { Typography, Link } from '@one-thd/sui-atomic-components';
import PropTypes from 'prop-types';

const PopoverOutOfRange = React.lazy(() => import(
  /* webpackChunkName: "popover" */
  '@one-thd/sui-atomic-components/dist/popover/Popover')
  .then(module => ({ default: module.Popover }))
// eslint-disable-next-line no-console
  .catch((error) => console.warn(`Error occurred while loading Popover - ${error}`)));

let deliveryStoreTimer = null;

const StoreAndZipOutOfRange = ({
  currentAnchorElt = {},
  storeClick = () => { },
  zipcodeClick = () => { }
}) => {

  const localizerRef = useRef();

  const [popUpMessage, setPopUpMessage] = useState({ open: false, name: null });
  const validateSoftLinkInit = () => {
    const validateSoftLinkKey = ({ storageKey, softLink }) => {
      sessionStorage.removeItem(storageKey);
      const deliveryZipDetails = localizerRef.current.getDeliveryZipcodeDetails();
      const zipCode = deliveryZipDetails.zipcode;
      const userType = deliveryZipDetails.userType;
      if (userType !== 'AUTO' && localizerRef.current) {
        localizerRef.current.validateSoftLink(zipCode, softLink);
      }
    };

    if (sessionStorage.getItem('deliveryzipReloading')) {
      validateSoftLinkKey({ storageKey: 'deliveryzipReloading', softLink: 'selectedZipNotInRange' });
      return;
    }

    if (sessionStorage.getItem('deliveryStoreReloading')) {
      validateSoftLinkKey({ storageKey: 'deliveryStoreReloading', softLink: 'selectedStoreNotInRange' });
    }
  };

  useEffect(() => {
    import('Localizer')
      .then((localizer) => {
        localizerRef.current = localizer;
        validateSoftLinkInit();
        deliveryStoreTimer = setTimeout(() => {
          localizerRef.current.on('selectedZipNotInRange', () => setPopUpMessage({ open: true, name: 'zipCode' }));
          localizerRef.current.on('selectedStoreNotInRange', () => setPopUpMessage({ open: true, name: 'store' }));
        }, 4000);
      })
    // eslint-disable-next-line no-console
      .catch((error) => console.warn(`Error occurred while loading Localizer - ${error}`));

    return () => {
      clearTimeout(deliveryStoreTimer);
    };

  }, []);

  const closeMsgContent = () => {
    if (popUpMessage.open) {
      setPopUpMessage({ open: false, name: null });
      window.removeEventListener('scroll', closeMsgContent);
    }
  };

  const zipCodeMsgContent = () => {
    const storeOnClick = () => { storeClick(); closeMsgContent(); };
    return (
      <>
        <Typography variant="body-xs">
          Looks like this store isn’t near your delivery area.
        </Typography>
        { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link onClick={storeOnClick} variant="body-xs">Need to update your store?</Link>
      </>
    );
  };

  const storeMsgContent = () => {
    const zipCodeOnClick = () => { zipcodeClick(); closeMsgContent(); };
    return (
      <>
        <Typography variant="body-xs">
          Looks like this area isn’t near your preferred store.
        </Typography>
        { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link onClick={zipCodeOnClick} variant="body-xs">Need to update your ZIP Code?</Link>
      </>
    );
  };

  const showPopMessage = popUpMessage.name === 'zipCode' ? zipCodeMsgContent() : storeMsgContent();
  const currentAnchorEltVal = popUpMessage.name === 'zipCode'
    ? currentAnchorElt?.deliveryElt : currentAnchorElt?.storeElt;
  const zipCodeStoreTitle = popUpMessage.name === 'zipCode' ? 'Store' : 'ZIP Code';

  return (
    <>
      {popUpMessage.open && (
        <Suspense fallback={null}>
          <PopoverOutOfRange
            open={popUpMessage.open}
            disablePortal
            id="popover-out-of-range"
            anchorEl={currentAnchorEltVal}
            placement="bottom"
            onClose={closeMsgContent}
            title={zipCodeStoreTitle}
            style={{ zIndex: 1000 }}
          >
            <Typography variant="body-xs">
              {showPopMessage}
            </Typography>
          </PopoverOutOfRange>
        </Suspense>
      )}
    </>
  );
};

StoreAndZipOutOfRange.propTypes = {
  currentAnchorElt: PropTypes.oneOfType([
    PropTypes.object
  ]),
  storeClick: PropTypes.func,
  zipcodeClick: PropTypes.func
};

StoreAndZipOutOfRange.displayName = 'StoreAndZipOutOfRange';

export { StoreAndZipOutOfRange };