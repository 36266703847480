import { useCallback, useState } from 'react';

const useClientNode = (data = {}) => {
  const {
    deps = []
  } = data;

  const [node, setNode] = useState(null);

  const setRectRef = useCallback((newNode) => {
    if (!newNode) return;

    setNode(newNode);
  }, [...deps]);

  return [node, setRectRef];
};

export default useClientNode;