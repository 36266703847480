const MenuData = (id, title, level) => ({
  id,
  title,
  level
});

const MenuIdDictionary = Object.freeze({
  SHOP_ALL_ID: 'shop-all',
  SERVICES_ID: 'services',
  DIY_ID: 'diy',
  MENU_ID: 'menu',
  EXCHANGE_ID: 'exchange'
});

const MenuLevelDictionary = Object.freeze({
  LEVEL_1: 'PrimaryNavigationL1',
  LEVEL_2: 'PrimaryNavigationL2',
  LEVEL_3: 'PrimaryNavigationL3',
  LEVEL_4: 'PrimaryNavigationL4',
  LEVEL_5: 'PrimaryNavigationL5'
});

const MenuDataDictionary = Object.freeze({
  SHOP_ALL:
    MenuData(
      MenuIdDictionary.SHOP_ALL_ID,
      'Shop All',
      MenuLevelDictionary.LEVEL_1
    ),
  SERVICES:
    MenuData(
      MenuIdDictionary.SERVICES_ID,
      'Services',
      MenuLevelDictionary.LEVEL_1
    ),
  DIY:
    MenuData(
      MenuIdDictionary.DIY_ID,
      'DIY',
      MenuLevelDictionary.LEVEL_1
    ),
  MENU:
    MenuData(
      MenuIdDictionary.MENU_ID,
      'Menu',
      MenuLevelDictionary.LEVEL_1
    ),
  EXCHANGE:
    MenuData(
      MenuIdDictionary.EXCHANGE_ID,
      'Menu',
      MenuLevelDictionary.LEVEL_1
    )
});

export { MenuDataDictionary, MenuIdDictionary, MenuLevelDictionary };
