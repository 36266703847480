import React, { useEffect, useMemo, useRef } from 'react';
import {
  bool, node, number, shape, func
} from 'prop-types';
import className from 'classnames';
import { DEBOUNCE_DELAY_MS } from '../utils/constants';
import { isValidAnchorElement } from '../utils/typeahead-utils';
import { typeaheadEvent } from '../events/publisher';
import { EventDictionary } from '../events/event-dictionary';
import HTMLElementType from '../utils/HTMLElementType';
import { useScrollLock } from '../hooks/useScrollLock';
import { SuggestionsMenuBackdrop } from './SuggestionsMenuBackdrop';
import { useResizeObserver } from '../hooks/useResizeObserver';

const SuggestionsMenu = ({
  open = false,
  anchorElement = undefined,
  fullScreenHeight = false,
  fullScreenWidth = false,
  onClickAway = () => { },
  backdropOffset = { top: undefined },
  isVisible = undefined,
  children = null
}) => {
  const openRef = useRef(false);

  const { lockScroll, unlockScroll } = useScrollLock();

  const isReadyToOpen = () => {
    return open && isValidAnchorElement(anchorElement);
  };

  const [entry] = useResizeObserver({ targetElement: anchorElement });

  const publishEventHandler = ({ isMenuOpen }) => {
    if (openRef.current === isMenuOpen) return;

    openRef.current = isMenuOpen;
    typeaheadEvent.publish(
      EventDictionary.SUGGESTIONS_MENU_OPEN_CLOSE_EVENT.name,
      { open: isMenuOpen }
    );
  };

  const getPositioningStyle = () => {
    if (!entry?.contentRect || fullScreenWidth) return null;

    return {
      width: `${entry?.contentRect?.width}px`
    };
  };

  useEffect(() => {
    if (open && isVisible) {
      lockScroll();
      publishEventHandler({ isMenuOpen: true });
    }

    return () => {
      unlockScroll();
      publishEventHandler({ isMenuOpen: false });
    };
  }, [open, isVisible]);

  const rootMenuClasses = className([
    'sui-z-max sui-absolute sui-bg-primary sui-shadow-base sui-min-w-40'
  ].join(' '),
  {
    'sui-hidden': !isVisible,
    'sui-h-screen': fullScreenHeight,
    'sui-left-0 sui-w-screen': fullScreenWidth
  });

  const style = getPositioningStyle();

  if (!isReadyToOpen()) return null;

  return (
    <>
      <div
        id="suggestions-menu-root"
        data-testid="suggestions-menu-root"
        className={rootMenuClasses}
        style={style}
      >
        { children }
      </div>
      <SuggestionsMenuBackdrop
        open={isVisible && open}
        backdropOffset={backdropOffset}
        handleClick={onClickAway}
      />
    </>
  );
};

SuggestionsMenu.displayName = 'SuggestionsMenu';

SuggestionsMenu.propTypes = {
  open: bool,
  anchorElement: HTMLElementType,
  backdropOffset: shape({ top: number }),
  fullScreenHeight: bool,
  fullScreenWidth: bool,
  onClickAway: func,
  isVisible: bool,
  children: node
};

export { SuggestionsMenu };
