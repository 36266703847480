import React, { useReducer } from 'react';
import { node } from 'prop-types';
import { DrawerContext, defaultContent } from './DrawerContext';
import { drawerReducer } from './DrawerReducer';

const DrawerProvider = ({ children = null }) => {
  const [drawerState, drawerDispatch] = useReducer(
    drawerReducer,
    undefined,
    defaultContent);

  return (
    <DrawerContext.Provider value={{ drawerState, drawerDispatch }}>
      {children}
    </DrawerContext.Provider>
  );
};

DrawerProvider.propTypes = {
  children: node
};
DrawerProvider.displayName = 'DrawerProvider';

export { DrawerProvider };
