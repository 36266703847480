/* eslint-disable max-len */
import React from 'react';

const HeaderServicesIcon = () => {
  return (
    <>
      <svg className="sui-w-4 sui-h-4 lg:sui-w-6 lg:sui-h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M18.5 11C21.5376 11 24 8.53757 24 5.5C24 2.46243 21.5376 0 18.5 0C15.4624 0 13 2.46243 13 5.5C13 8.53757 15.4624 11 18.5 11ZM21.75 5.5C21.75 7.29493 20.2949 8.75 18.5 8.75C16.7051 8.75 15.25 7.29493 15.25 5.5C15.25 3.70507 16.7051 2.25 18.5 2.25C20.2949 2.25 21.75 3.70507 21.75 5.5Z" className="sui-fill-current" />
        <path fillRule="evenodd" clipRule="evenodd" d="M5.50002 24C8.53758 24 11 21.5376 11 18.5C11 15.4625 8.53758 13 5.50002 13C2.46245 13 1.52588e-05 15.4625 1.52588e-05 18.5C1.52588e-05 21.5376 2.46245 24 5.50002 24ZM8.75002 18.5C8.75002 20.295 7.29494 21.75 5.50002 21.75C3.70509 21.75 2.25002 20.295 2.25002 18.5C2.25002 16.7051 3.70509 15.25 5.50002 15.25C7.29494 15.25 8.75002 16.7051 8.75002 18.5Z" className="sui-fill-current" />
        <path fillRule="evenodd" clipRule="evenodd" d="M24 18.5C24 21.5376 21.5376 24 18.5 24C15.4625 24 13 21.5376 13 18.5C13 15.4625 15.4625 13 18.5 13C21.5376 13 24 15.4625 24 18.5ZM18.5 21.75C20.295 21.75 21.75 20.295 21.75 18.5C21.75 16.7051 20.295 15.25 18.5 15.25C16.7051 15.25 15.25 16.7051 15.25 18.5C15.25 20.295 16.7051 21.75 18.5 21.75Z" className="sui-fill-current" />
        <path fillRule="evenodd" clipRule="evenodd" d="M11 5.5C11 8.53757 8.53757 11 5.5 11C2.46243 11 0 8.53757 0 5.5C0 2.46243 2.46243 0 5.5 0C8.53757 0 11 2.46243 11 5.5ZM5.5 8.75C7.29493 8.75 8.75 7.29493 8.75 5.5C8.75 3.70507 7.29493 2.25 5.5 2.25C3.70508 2.25 2.25 3.70507 2.25 5.5C2.25 7.29493 3.70508 8.75 5.5 8.75Z" className="sui-fill-current" />
      </svg>
    </>
  );
};

export default HeaderServicesIcon;