import searchHistoryStore, { searchHistoryFormatter } from './searchHistory';
import { typeaheadAPI } from './typeaheadAPI';
import { CONSTANTS } from '../../../../constants';

const { TYPEAHEAD_DATA_SOURCE } = CONSTANTS;

/**
 * Orchestrator to fetch search suggestions from search history local storage or Typeahead API.
 */
export const searchSuggestions = {
  fetch: async ({ searchText, config }) => {
    const searchHistoryResults = searchHistoryStore.get();
    const hasHistory = searchHistoryResults?.length > 0;
    const historyEnhancedResults = searchHistoryStore.format(searchHistoryResults);

    if (searchText?.length === 0) {

      return {
        source: TYPEAHEAD_DATA_SOURCE.SEARCH_HISTORY,
        hasData: hasHistory,
        isActive: true,
        data: historyEnhancedResults,
      };
    }

    if (searchText?.length > 0) {
      const typeaheadResults = await typeaheadAPI.fetch({
        searchText,
        config,
        recentSearches: hasHistory ? searchHistoryResults : []
      });

      const getFirstTwoHistories = searchHistoryFormatter.filterByTitleStartsWith(
        historyEnhancedResults,
        searchText,
        2);
      const formatTypeAheadArray = typeaheadAPI.format(typeaheadResults?.results) || [];

      return {
        source: TYPEAHEAD_DATA_SOURCE.TA_V2_SEARCH_API,
        hasData: typeaheadResults?.results?.length > 0,
        isActive: true,
        data: [...getFirstTwoHistories, ...formatTypeAheadArray],
        cta: typeaheadResults?.cta
      };
    }

    return { source: null, hasData: false, isActive: false, data: [] };
  }
};
