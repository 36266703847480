import { isTypeaheadEvent } from './event-dictionary';

export const typeaheadEvent = {
  publish: (eventName, payload = {}) => {
    if (typeof window === 'undefined'
        || !window?.LIFE_CYCLE_EVENT_BUS
        || !isTypeaheadEvent(eventName)) return;

    window.LIFE_CYCLE_EVENT_BUS.trigger(`typeahead.${eventName}`, payload);
  }
};