import React, { Suspense, useEffect } from 'react';
import { bool } from 'prop-types';
import { IconButton } from '@one-thd/sui-atomic-components';
import { ArrowBack } from '@one-thd/sui-icons';
import PositionedHeader from './PositionedHeader';
import { HeaderThdLogo } from './HeaderThdLogo';
import { HeaderNavigationButtons } from './HeaderNavigationButtons';
import { HeaderStoreAndDelivery } from './HeaderStoreAndDelivery';
import { HeaderController } from './HeaderController';
import { HeaderSearch } from './HeaderSearch';
import { HeaderBarcodeSearch } from './HeaderBarcodeSearch';
import { CartIcon } from './CartIcon';
import { AdaptiveUIEventSubscriber } from '../generic-ui/AdaptiveUIEventSubscriber';
import TopHat from './TopHat';
import { B2C_NAV_BUTTONS } from './HeaderNavigationButtonsDictionary';
import { HeaderQuickAccess } from './HeaderQuickAccess';

const Header = () => {
  const headerContentClasses = [
    'sui-flex sui-font-regular sui-items-center',
    'sui-bg-primary sui-text-primary',
    'sui-px-4 sui-pt-4 sui-pb-12 sui-gap-2',
    'sui-bg-primary lg:sui-pb-4 lg:sui-gap-6'
  ].join(' ');

  return (
    <HeaderController
      theme={{ inverseTheme: false }}
      customerType="B2C"
      featureSwitches={{ isRecentlyViewedEnabled: true, isMagicApronAssistantEnabled: true }}
    >
      <PositionedHeader
        topHatComponent={<TopHat />}
      >
        <HeaderQuickAccess />
        <div className={headerContentClasses}>
          <AdaptiveUIEventSubscriber
            event="typeahead.suggestions-menu-open-close"
            primaryNode={<HeaderThdLogo />}
            secondaryNode={(
              <IconButton
                data-testid="header-back-arrow-button"
                icon={ArrowBack}
                iconSize="regular"
              />
            )}
          />
          <HeaderStoreAndDelivery mobileOnly={false} />
          <HeaderSearch />
          {/* Start of Mobile only components */}
          <AdaptiveUIEventSubscriber
            event="typeahead.suggestions-menu-open-close"
            primaryNode={<CartIcon />}
            secondaryNode={<HeaderBarcodeSearch />}
            mobileOnly
          />
          <HeaderStoreAndDelivery mobileOnly />
          {/* End of Mobile only components */}
          <HeaderNavigationButtons navButtonsData={B2C_NAV_BUTTONS} />
          <AdaptiveUIEventSubscriber
            event="typeahead.suggestions-menu-open-close"
            primaryNode={<CartIcon />}
            secondaryNode={<HeaderBarcodeSearch />}
            mobileOnly={false}
          />
        </div>
      </PositionedHeader>
    </HeaderController>
  );
};

Header.displayName = 'Header';

export { Header };
