import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import createTypography from '../utils/createTypography';
import { ButtonBase } from '../private/components/button/ButtonBase';
import useEnhancedEffect from '../private/hooks/useEnhancedEffect';
import useForkRef from '../private/hooks/useForkRef';

/**
 * Component used to provide the value and text for each option inside a `Menu` or `Dropdown` component.
 *
 * Related components: [Dropdown](#dropdown), [ListItemText](#listitemtext), [DropdownField](#dropdownfield),
 * [NativeDropdown](#nativedropdown), [DynamicDropdown](#dynamicdropdown), [MenuList](#menulist)
 *
 * Usage:
 *
 * ```jsx
 * import { MenuItem } from '@one-thd/sui-atomic-components';
 * ```
 */
const MenuItem = React.forwardRef((props, ref) => {

  const {
    autoFocus = false,
    children,
    component = 'li',
    disableGutters = false,
    role = 'menuitem',
    selected,
    tabIndex: tabIndexProp,
    ...other
  } = props;

  const menuItemRef = React.useRef(null);
  useEnhancedEffect(() => {
    if (autoFocus && menuItemRef.current) {
      requestAnimationFrame(() => {
        menuItemRef?.current?.focus();
      });
    }
  }, [autoFocus]);

  const handleRef = useForkRef(menuItemRef, ref);

  const ownerState = {
    ...props,
    disableGutters,
  };

  let tabIndex;
  if (!props.disabled) {
    tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
  }

  const typography = createTypography({
    variant: 'body-base',
    weight: 'regular',
    height: 'normal',
    color: props.disabled ? 'inactive' : 'primary'
  });

  const classes = classNames('sui-group/sui-menu-item sui-flex sui-grow sui-justify-start sui-items-center sui-relative sui-text-left sui-py-2',
    'focus-visible:sui-bg-inverse focus-visible:sui-text-inverse focus-visible:sui-border-solid focus-visible:sui-border-highlight focus-visible:sui-border-4', {
      'sui-px-4': !ownerState.disableGutters,
      'sui-bg-strong hover:sui-bg-strong/[.75]': selected,
      'hover:sui-bg-subtle': !selected,
      'sui-bg-primary': props.disabled
    }, typography.classes);

  return (
    <ButtonBase
      ref={handleRef}
      role={role}
      tabIndex={tabIndex}
      component={component}
      className={classes}
      {...other}
    >
      {children}
    </ButtonBase>
  );
});

MenuItem.displayName = 'MenuItem';

MenuItem.propTypes = {
  /**
   * If `true`, the list item is focused during the first mount.
   * Focus will also be triggered if the value changes from false to true.
   * @default false
   */
  autoFocus: PropTypes.bool,
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * @ignore
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the left and right padding is removed.
   * @default false
   */
  disableGutters: PropTypes.bool,
  /**
   * @ignore
   */
  role: PropTypes.string,
  /**
   * @ignore
   */
  selected: PropTypes.bool,
  /**
   * @ignore
   * @default 0
   */
  tabIndex: PropTypes.number,
};

export { MenuItem };
