import THDStorageUtils, { LOCAL_STORAGE, SESSION_STORAGE } from '@thd-olt-global/thd-storage-utils';
import { Clock } from '@one-thd/sui-icons';
import { CONSTANTS } from '../../../../constants';

const searchHistoryStore = {
  get: () => {
    try {
      const history = THDStorageUtils.get(LOCAL_STORAGE, { key: CONSTANTS.SEARCH_HISTORY_STORAGE_KEY });
      if (!history || history === 'undefined') return [];
      return JSON.parse(history).slice(0, 10);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error({
        message: `Error occurred while accessing local storage - ${CONSTANTS.SEARCH_HISTORY_STORAGE_KEY}`,
        error
      });
      return [];
    }
  },

  deleteSingleHistory: (selectedSearchTerm) => {
    if (!selectedSearchTerm) return;
    const history = THDStorageUtils.get(LOCAL_STORAGE, { key: CONSTANTS.SEARCH_HISTORY_STORAGE_KEY });
    if (history && history !== 'undefined') {
      const filteredHistory = JSON.parse(history).filter(({ keyword }) => selectedSearchTerm !== keyword);
      const newHistory = JSON.stringify(filteredHistory);
      THDStorageUtils.set(LOCAL_STORAGE, { key: CONSTANTS.SEARCH_HISTORY_STORAGE_KEY, value: newHistory });
    }
  },

  deleteAllHistory: () => {
    THDStorageUtils.remove(LOCAL_STORAGE, { key: CONSTANTS.SEARCH_HISTORY_STORAGE_KEY });
  },

  update: (searchInput) => {
    if (!searchInput || searchInput?.length === 0) return;

    try {
      const history = THDStorageUtils.get(LOCAL_STORAGE, { key: CONSTANTS.SEARCH_HISTORY_STORAGE_KEY });
      if (history && history !== 'undefined') {
        const filteredHistory = JSON.parse(history).filter(({ keyword }) => {
          return keyword !== searchInput;
        });
        const newHistory = JSON.stringify([{ keyword: searchInput }, ...filteredHistory]);
        THDStorageUtils.set(LOCAL_STORAGE, { key: CONSTANTS.SEARCH_HISTORY_STORAGE_KEY, value: newHistory });
      } else {
        const newHistory = JSON.stringify([{ keyword: searchInput }]);
        THDStorageUtils.set(LOCAL_STORAGE, { key: CONSTANTS.SEARCH_HISTORY_STORAGE_KEY, value: newHistory });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error({
        message: `Error occurred while updating local storage - ${CONSTANTS.SEARCH_HISTORY_STORAGE_KEY}`,
        error
      });
    }
  },

  format: (data) => {
    return data?.map(({ keyword }) => {
      const cleanKeyword = decodeURIComponent(keyword) || keyword;
      return ({
        link: `/s/${keyword}`,
        title: cleanKeyword,
        icon: Clock,
        source: CONSTANTS.TYPEAHEAD_DATA_SOURCE.SEARCH_HISTORY,
        removable: true
      });
    });
  },

  updateLastSearchTerm: (searchLastInputValue) => {
    if (!searchLastInputValue) return;
    try {

      const newLastValue = JSON.stringify(searchLastInputValue);
      THDStorageUtils.set(SESSION_STORAGE, { key: CONSTANTS.SEARCH_INPUT_VALUE, value: newLastValue });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error({
        message: 'Error occurred while updating session storage - header_search_last_input_value',
        error
      });
    }
  }
};

export const searchHistoryFormatter = {
  filterByTitleStartsWith: (enhancedSearchHistory = [], searchText = '', limit = 2) => {
    return enhancedSearchHistory?.reduce((acc, history) => {
      if (history.title.toLocaleLowerCase().startsWith(searchText.toLocaleLowerCase())) acc.push(history);
      return acc.slice(0, limit);
    }, []) || [];
  }
};

export default searchHistoryStore;
