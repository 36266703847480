import React, { forwardRef, memo } from 'react';
import { useThemeContext } from '../generic-ui/ThemeContext';

const TopHat = forwardRef((props, ref) => {
  const { inverseTheme } = useThemeContext();

  const messageBarId = inverseTheme ? 'messageBarB2B' : 'messageBar';
  const messageBarClass = inverseTheme ? 'MessageBarB2B' : 'MessageBar';

  const topHatClasses = [
    'sui-relative sui-z-tophat',
    'sui-flex sui-grow sui-leading-none sui-justify-center sui-items-center sui-text-center',
    'sui-h-8 sui-align-middle sui-w-full sui-font-regular',
    'sui-bg-brand sui-text-inverse sui-box-content sui-inline-block sui-text-sm',
    '[&_svg]:sui-ml-1 [&_svg]:sui-inline-block'
  ].join(' ');

  return (
    <div id="header-tophat" className={topHatClasses} ref={ref} data-component="HeaderTopHat">
      <div
        id={messageBarId}
        className={messageBarClass}
        style={{ position: 'static' }}
      >
        <span>#1 Home Improvement Retailer</span>
      </div>
    </div>
  );
});

TopHat.displayName = 'TopHat';

export default memo(TopHat);