import { useEffect, useState } from 'react';
import THDStorageUtils, { COOKIE_STORAGE } from '@thd-olt-global/thd-storage-utils';

const THD_STOREFRONT_COOKIE_KEY = 'THD_STOREFRONT';

/**
 * `useStorefront` hook to fetch data from the THD_STOREFRONT cookie
 */
const useStorefront = (props = {}) => {
  const { skip = false } = props;

  const [storefront, setStorefront] = useState();

  const storefrontStorage = {
    get: () => {
      try {
        const data = THDStorageUtils.get(COOKIE_STORAGE, { key: THD_STOREFRONT_COOKIE_KEY });
        if (data === 'undefined' || !data) {
          return null;
        }
        const payload = data.split('.')[1];
        if (payload) {
          const decodedPayload = Buffer.from(payload, 'base64').toString();
          return JSON.parse(decodedPayload);
        }
        return JSON.parse(data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error({
          message: `Error occurred while accessing cookie - ${THD_STOREFRONT_COOKIE_KEY}`,
          error
        });
        return null;
      }
    }
  };

  useEffect(() => {
    if (!skip) {
      setStorefront(storefrontStorage.get());
    }
  }, []);

  return [storefront];
};

export default useStorefront;
