import React from 'react';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';

const DeliveryZipCode = React.lazy(() => import(
  /* webpackChunkName: "DeliveryZipCode" */
  '@thd-olt-component-react/delivery-zip-code')
  .then(module => ({ default: module.DeliveryZipCode }))
// eslint-disable-next-line no-console
  .catch((error) => console.warn(`Error occurred while lazy loading delivery zip code - ${error}`)));

const DeliveryZipCodeContent = () => {

  return (
    <ErrorBoundary id="header-delivery-zip-code" name="header-delivery-zip-code">
      <DeliveryZipCode />
    </ErrorBoundary>
  );
};

DeliveryZipCodeContent.displayName = 'DeliveryZipCodeContent';

export default DeliveryZipCodeContent;
