import { useRef, useCallback } from 'react';

export const useScrollLock = () => {
  const isScrollbarVisibleRef = useRef(null);
  const scrollbarSizeRef = useRef(null);

  if (typeof window !== 'undefined' && isScrollbarVisibleRef.current === null) {
    isScrollbarVisibleRef.current = window.innerWidth > document.documentElement.clientWidth;
  }

  if (typeof window !== 'undefined' && scrollbarSizeRef.current === null) {
    scrollbarSizeRef.current = Math.abs(window.innerWidth - document.documentElement.clientWidth);
  }

  const lockScroll = useCallback(() => {
    if (typeof document !== 'undefined') {
      if (isScrollbarVisibleRef.current) {
        document.body.style.paddingRight = `${scrollbarSizeRef.current}px`;
      }
    }
    document.body.style.overflow = 'hidden';
  }, []);

  const unlockScroll = useCallback(() => {
    if (typeof document !== 'undefined') {
      document.body.style.removeProperty('padding-right');
      document.body.style.removeProperty('overflow');
    }
  }, []);

  return { lockScroll, unlockScroll };
};
