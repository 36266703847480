import { useState, useEffect, useRef } from 'react';
import { debounce } from '@thd-olt-functional/utils';
import { DEBOUNCE_DELAY_MS } from '../utils/constants';

export const useResizeObserver = ({ targetElement }) => {
  const [entry, setEntry] = useState({});

  const resizeObserverRef = useRef(null);

  const resizeHandler = debounce(DEBOUNCE_DELAY_MS.DEFAULT, ([observedEntry]) => setEntry(() => {
    return { contentRect: observedEntry.contentRect };
  }));

  if (typeof window !== 'undefined' && resizeObserverRef.current === null && !!window.ResizeObserver) {
    resizeObserverRef.current = new window.ResizeObserver(resizeHandler);
  }

  useEffect(() => {
    if (resizeObserverRef.current && targetElement) {
      resizeObserverRef.current.observe(targetElement);
    }

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [targetElement]);

  return [entry];
};
