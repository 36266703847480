/* react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import { shape, bool, number, func } from 'prop-types';

const SuggestionsMenuBackdrop = forwardRef((
  {
    handleClick,
    open,
    backdropOffset
  }, ref) => {

  const backdropClasses = [
    'sui-bg-inverse/50 sui-left-0 sui-right-0 sui-h-screen',
    'sui-absolute sui-items-center sui-justify-center',
  ].join(' ');

  const style = {
    backdrop: {
      top: typeof backdropOffset?.top === 'number' ? `${+backdropOffset.top}px` : null,
    }
  };

  if (!open) return null;

  return (
    <div
      data-testid="suggestion-menu-backdrop"
      onClick={handleClick}
      ref={ref}
      className={backdropClasses}
      style={style.backdrop}
      aria-hidden
    />
  );
});

SuggestionsMenuBackdrop.displayName = 'SuggestionsMenuBackdrop';

SuggestionsMenuBackdrop.propTypes = {
  handleClick: func,
  open: bool,
  backdropOffset: shape({
    top: number,
  }),
};

SuggestionsMenuBackdrop.defaultProps = {
  handleClick: undefined,
  open: false,
  backdropOffset: shape({
    top: undefined,
  }),
};

export { SuggestionsMenuBackdrop };
